import { archivedInvoice, request_task } from 'src/api'
import useSWR from 'swr'
import { useNewApiClient } from './useNewApiClient'

export const useTasks = () => {
  const { apiClient } = useNewApiClient()
  const { data, mutate } = useSWR('/tasks/current', async () => {
    return await apiClient.task.getTasksCurrent()
  })

  const postDevData = async () => {
    await apiClient.request.request({
      method: 'POST',
      url: '/dev/tasks_users'
    })
    await mutate()
  }

  const deleteInvoiceTask = async (params: archivedInvoice) => {
    await apiClient.task.patchArchivedInvoices(params)
    await mutate()
  }
  const deleteDocumentTask = async (taskId: number, params: request_task) => {
    await apiClient.task.patchTasksTaskId(taskId, params)
    await mutate()
  }
  return {
    tasks: data,
    postDevData,
    deleteInvoiceTask,
    deleteDocumentTask
  }
}
