import { createIcon } from '@chakra-ui/react'

export const OutlineCardIcon = createIcon({
  displayName: 'OutlineCardIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83334 5.41406V3.7474C5.83334 3.28716 6.20643 2.91406 6.66668 2.91406H17.5C17.9603 2.91406 18.3333 3.28716 18.3333 3.7474V11.2474C18.3333 11.7076 17.9603 12.0807 17.5 12.0807H16.6667"
        stroke="#A0A0A0"
        strokeWidth="1.5"
      />
      <path
        d="M13.3333 7.91406H2.49999C2.03975 7.91406 1.66666 8.28716 1.66666 8.7474V16.2474C1.66666 16.7076 2.03975 17.0807 2.49999 17.0807H13.3333C13.7936 17.0807 14.1667 16.7076 14.1667 16.2474V8.7474C14.1667 8.28716 13.7936 7.91406 13.3333 7.91406Z"
        stroke="#A0A0A0"
        strokeWidth="1.5"
      />
      <path d="M1.66666 11.6641H14.1667" stroke="#A0A0A0" strokeWidth="1.5" />
      <path d="M14.1667 9.58594V14.5859" stroke="#A0A0A0" strokeWidth="1.5" />
      <path d="M1.66666 9.58594V14.5859" stroke="#A0A0A0" strokeWidth="1.5" />
      <path d="M4.58334 14.375H9.58334" stroke="#A0A0A0" strokeWidth="1.5" />
    </svg>
  )
})
