import { Box, Center, Flex, Tab, TabProps, Text } from '@chakra-ui/react'
import { CompleteCheckCircleIcon } from 'src/components/common/Icons/CompleteCheckCircleIcon'

type Props = {
  count?: number
  selected: boolean
  completed: boolean
  icon: JSX.Element
}

export const EntireFlowHeader: React.FC<Props> = ({
  children,
  count,
  selected,
  completed,
  icon
}) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      w="100%"
      h="64px"
      px="16px"
      pos="relative"
      {...(selected && {
        sx: {
          boxShadow: '3px 0 0 0 rgba(110, 125, 202, 1) inset',
          bgColor: 'purple.50',
          '& #arrow': {
            stroke: 'primary.purple'
          },
          '& #task-tab-icon path': {
            stroke: 'white.100'
          }
        }
      })}
    >
      <Flex gap="12px" align="center">
        <Box
          w="1px"
          bgColor="primary.purple"
          h="100%"
          position="absolute"
          top="0"
          left="28px"
          zIndex={0}
          className="task-tab-vertical-bar"
        />
        <Center
          zIndex={1}
          w="44px"
          h="44px"
          borderRadius="full"
          bgColor={
            completed
              ? selected
                ? 'purple.50'
                : 'white.100'
              : selected
              ? 'primary.purple'
              : 'gray.70'
          }
          border="1px solid"
          borderColor={
            completed
              ? selected
                ? 'purple.50'
                : 'white.100'
              : selected
              ? 'primary.purple'
              : 'purple.200'
          }
        >
          {completed ? (
            <CompleteCheckCircleIcon w="28px" h="28px" />
          ) : (
            <Box id="task-tab-icon">{icon}</Box>
          )}
        </Center>
        <Flex align="center" gap="6px">
          <Text
            color={selected ? 'primary.black' : 'gray.400'}
            fontWeight={700}
            fontSize="14px"
          >
            {children}
          </Text>
          {count ? (
            <Text
              w={['18px', '20px']}
              h={['18px', '20px']}
              fontSize={['11px', '12px']}
              lineHeight={['11px', '12px']}
              py={['3.5px', '4px']}
              borderRadius="full"
              color="white"
              bgColor={selected ? 'primary.purple' : 'gray.400'}
            >
              {count}
            </Text>
          ) : null}
        </Flex>
      </Flex>
      <Box
        transform={[`rotate(${selected ? 270 : 90}deg)`, 'none']}
        transition="transform ease .5s"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="arrow"
            d="M6.33334 4L10.3333 8L6.33334 12"
            stroke="rgba(196, 196, 196, 1)"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
      </Box>
    </Flex>
  )
}
export const MonthlyTasksTabPanelSubTab: React.FC<TabProps & Props> = (
  props
) => {
  const { children, count, selected, completed, icon, ...tabProps } = props
  return (
    <Tab
      w="100%"
      p="0"
      _focus={{
        boxShadow: 'none'
      }}
      {...tabProps}
    >
      <EntireFlowHeader {...{ children, count, selected, completed, icon }} />
    </Tab>
  )
}
