import {
  Box,
  ButtonProps,
  Center,
  Flex,
  LinkProps,
  Text
} from '@chakra-ui/react'
import { format, isAfter } from 'date-fns'
import React, { useMemo } from 'react'
import { response_task } from 'src/api'
import { isInvalidDate } from 'src/common/DateUtils'
import { TaskButton } from '../TaskButton'

type Props = {
  icon: JSX.Element
  task: response_task
}

export const IconTaskCardButton = React.memo(
  React.forwardRef<
    HTMLButtonElement,
    Props & ButtonProps & LinkProps & { isDemo: boolean }
  >(({ icon, task, ...props }, ref) => {
    const isRequired = task.task_type === response_task.task_type.REQUIRED
    const expirationDateObject = useMemo(
      () =>
        task.expiration_date ? new Date(task.expiration_date) : new Date(''),
      [task]
    )

    return (
      <TaskButton
        w="100%"
        minH={'56px'}
        h={['auto', '56px']}
        px={['8px', '16px']}
        py={['16px', '18px']}
        justifyContent="space-between"
        ref={ref}
        _hover={{ backgroundColor: 'gray.50' }}
        flexWrap="nowrap"
        whiteSpace="normal"
        linkType={task.url_type}
        {...props}
      >
        <Flex
          gap="10px"
          align="center"
          flexWrap="nowrap"
          justify={['space-between', 'flex-start']}
          w={['100%', 'fit-content']}
        >
          <Center minW="1%" display={['none', 'block']}>
            {icon}
          </Center>
          <Text
            noOfLines={1}
            fontSize={['14px', '15px']}
            fontWeight={500}
            lineHeight={1}
            wordBreak="break-word"
            minW="0"
          >
            {task.name}
          </Text>
          <Box
            bgColor={isRequired ? 'primary.purple' : 'white.100'}
            color={isRequired ? 'white.100' : 'gray.800'}
            borderRadius="2px"
            border={isRequired ? 'none' : '1px solid'}
            borderColor="gray.200"
            fontSize="11px"
            lineHeight={1}
            fontWeight={500}
            py="3.5px"
            px="5px"
            minW="34px"
          >
            {isRequired ? '必須' : '推奨'}
          </Box>
        </Flex>
        <Text
          display={['none', 'block']}
          fontSize="13px"
          fontWeight={400}
          color={
            isAfter(new Date(), expirationDateObject)
              ? 'primary.alert'
              : 'gray.700'
          }
        >
          {/* https://www.notion.so/ce067526c1a049e88fd6ae245ce872cb */}
          {task.expiration_date && !isInvalidDate(expirationDateObject)
            ? `~ ${format(expirationDateObject, 'yyyy/MM/dd')}`
            : task.expiration_date}
        </Text>
      </TaskButton>
    )
  })
)
