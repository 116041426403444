import { createIcon } from '@chakra-ui/react'

export const MedicalDeducationIcon = createIcon({
  displayName: 'MedicalDeducationIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M16.5 2.5H2.5V21.5H16.5V2.5Z"
        fill="#6E7DCA"
        stroke="#0D0D0D"
        strokeWidth="2"
      />
      <path
        d="M16.5 10.5H21.5V21.5H16.5"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M6.5 10.5H12.5"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M9.5 7.5V13.5"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  )
})
