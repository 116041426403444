import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TabPanelProps,
  Text
} from '@chakra-ui/react'
import NextLink from 'next/link'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { response_task } from 'src/api'
import { BuildingIcon } from 'src/components/common/Icons/BuildingIcon'
import { HometownTaxDonationIcon } from 'src/components/common/Icons/HometownTaxDonationIcon '
import { HospitalIcon } from 'src/components/common/Icons/HospitalIcon'
import { InvoiceIcon } from 'src/components/common/Icons/InvoiceIcon'
import { MedicalDeducationIcon } from 'src/components/common/Icons/MedicalDeducationIcon'
import { MynumberIcon } from 'src/components/common/Icons/MynumberIcon'
import { NenkinIcon } from 'src/components/common/Icons/Nenkin'
import { OpeningNotificationIcon } from 'src/components/common/Icons/OpeningNotificationIcon'
import { TrashIcon } from 'src/components/common/Icons/TrashIcon'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useTasks } from 'src/hooks/useTasks'
import { INVOICES_PATH, PAGE_PATH } from 'src/routes'
import { IconTaskCardButton } from '../IconTaskCardButton'
import { MonthlyTasksTabPanelSubTabPanel } from '../MonthlyTasksTabPanelSubTabPanel'

export const documentTasks = [
  {
    icon: <InvoiceIcon w="20px" h="20px" />,
    label: '請求書',
    path: INVOICES_PATH,
    linkType: 'internal'
  },
  {
    icon: <BuildingIcon w="20px" h="20px" />,
    label: '給与（源泉徴収票）',
    path: 'https://thoughtful-argon-5a8.notion.site/f325c423d36746abb070334e23932b6a',
    linkType: 'external'
  },
  {
    icon: <EditIcon w="20px" h="20px" />,
    label: '予定納税',
    path: 'https://thoughtful-argon-5a8.notion.site/98ccbf1b6e0b43538ff53120c06cfb5d',
    linkType: 'external'
  },
  {
    icon: <NenkinIcon w="20px" h="20px" />,
    label: '国民年金',
    path: 'https://thoughtful-argon-5a8.notion.site/e46c6c4e70564ce19be968d37bf986f7',
    linkType: 'external'
  },
  {
    icon: <HospitalIcon w="20px" h="20px" />,
    label: '国民健康保険',
    path: 'https://thoughtful-argon-5a8.notion.site/490e7517cd6546b482324b5580e3b56f',
    linkType: 'external'
  }
]

export const saveTaxTasks = [
  {
    label: '開業届（青色申告）',
    icon: <OpeningNotificationIcon height={'20px'} width={'20px'} />,
    path: PAGE_PATH.tax_tasks.opening.index,
    linkType: 'internal'
  },
  {
    label: 'ふるさと納税',
    icon: <HometownTaxDonationIcon height={'20px'} width={'20px'} />,
    path: 'https://thoughtful-argon-5a8.notion.site/f607c435e547454ead2ab3da280aa16e',
    linkType: 'external'
  },
  {
    label: 'マイナンバーカード',
    icon: <MynumberIcon height={'20px'} width={'20px'} />,
    path: 'https://thoughtful-argon-5a8.notion.site/6e726faa89354da8b8c4d6de376d0f86',
    linkType: 'external'
  },
  {
    label: '医療費控除',
    icon: <MedicalDeducationIcon height={'20px'} width={'20px'} />,
    path: 'https://thoughtful-argon-5a8.notion.site/cd829dcb95a440e5afa226bc76c5f9d9',
    linkType: 'external'
  }
]

export const allFixedTasks = [...documentTasks, ...saveTaxTasks]

type Props = {
  document: response_task[]
  isDemo: boolean
}
export const DocumentPanel: React.FC<TabPanelProps & Props> = ({
  document,
  isDemo,
  ...props
}) => {
  const { deleteDocumentTask } = useTasks()

  const { processing_year } = useProcessingYear()
  const [deleteItem, setDeleteItem] = useState<response_task>()

  const closeDeleteModal = () => {
    setDeleteItem(undefined)
  }

  const deleteTask = async () => {
    if (!processing_year || !deleteItem?.id) return

    await deleteDocumentTask(deleteItem.id, deleteItem)
    closeDeleteModal()
  }

  return (
    <MonthlyTasksTabPanelSubTabPanel {...props}>
      <Flex flexDir="column" gap="8px">
        {document
          .map((task, i) => {
            const taskButton = (
              <NextLink passHref href={task.url || ''} key={i}>
                <IconTaskCardButton
                  isDemo={isDemo}
                  task={task}
                  icon={
                    allFixedTasks.find(({ label }) => label === task.name)
                      ?.icon || <EditIcon w="20px" h="20px" />
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  pos="relative"
                />
              </NextLink>
            )
            return isMobile || isDemo ? (
              taskButton
            ) : (
              <Box key={i}>
                <Popover trigger="hover" placement="right" closeDelay={200}>
                  <PopoverTrigger>
                    <Box width="80%">{taskButton}</Box>
                  </PopoverTrigger>
                  <PopoverContent bgColor="white.100" w="auto">
                    <Popover trigger="hover" placement="top" closeDelay={200}>
                      <PopoverTrigger>
                        <IconButton
                          _focus={{
                            bosShadow: 'none'
                          }}
                          variant="ghost"
                          aria-label="請求書タスクの削除"
                          icon={<TrashIcon />}
                          onClick={() =>
                            setDeleteItem({ ...task, is_archived: true })
                          }
                        />
                      </PopoverTrigger>
                      <PopoverContent
                        bgColor="white.100"
                        w="auto"
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="2px"
                      >
                        <Text
                          fontSize="12px"
                          lineHeight={1}
                          py="10px"
                          px="12px"
                        >
                          削除
                        </Text>
                      </PopoverContent>
                    </Popover>
                  </PopoverContent>
                </Popover>
              </Box>
            )
          })
          .reverse()}
      </Flex>

      {/* 削除 */}
      <Modal isOpen={!!deleteItem} onClose={closeDeleteModal} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text fontWeight={700} fontSize="15px" lineHeight="24px" mt="10px">
              {`タスク「${deleteItem?.name}」を削除しますか？`}
              <br />
              この操作は取り消せません！
            </Text>
          </ModalBody>

          <ModalFooter backgroundColor="gray.70">
            <Button
              variant="outline"
              color="gray.800"
              fontSize="13px"
              mr="12px"
              size="sm"
              onClick={closeDeleteModal}
            >
              キャンセル
            </Button>
            <Button
              fontSize="13px"
              onClick={deleteTask}
              size="sm"
              bgColor="primary.alert"
            >
              削除
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MonthlyTasksTabPanelSubTabPanel>
  )
}
