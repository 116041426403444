import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { PAGE_PATH } from 'src/routes'

export const useTaxReturnPageLink = () => {
  const { processing_year } = useProcessingYear()
  const year = processing_year?.processing_year || new Date().getFullYear()

  return {
    basic_information: PAGE_PATH.tax_return.basic_information(year),
    income_and_expenditure_information:
      PAGE_PATH.tax_return.income_and_expenditure_information(year),
    confirm: PAGE_PATH.tax_return.confirm(year),
    complete: PAGE_PATH.tax_return.complete(year),
    submit: PAGE_PATH.tax_return.submit(year),
    detail_edit: PAGE_PATH.tax_return.detail_edit(year),
    final_check: PAGE_PATH.tax_return.final_check(year)
  }
}
