import { Box, Text } from '@chakra-ui/react'

type Props = {
  subtitle: string | JSX.Element
  content: JSX.Element
}
export const EntireFlowTabContent: React.FC<Props> = ({
  subtitle,
  content
}) => {
  return (
    <Box p="20px" h="100%" w="100%">
      <Text
        fontSize="18px"
        fontWeight={700}
        lineHeight="27px"
        borderBottom="1px solid"
        borderColor="gray.200"
        pb="8px"
        mb="16px"
      >
        {subtitle}
      </Text>
      {content}
    </Box>
  )
}
