import useSWRImmutable from 'swr/immutable'
import { useApiClient } from './useApiClient'

export const useUserIsReady = () => {
  const { apiClient } = useApiClient()

  const { data: isReady, mutate } = useSWRImmutable(
    `/users/is_ready`,
    async () => {
      return await apiClient.getUserIsReady()
    }
  )

  const patchUserIsReady = async () => {
    await apiClient.patchUserIsReady()
    await mutate()
  }

  return {
    isReady,
    mutateUserIsReady: mutate,
    patchUserIsReady
  }
}
