import {
  ButtonProps,
  CircularProgress,
  Flex,
  LinkProps,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { TaskButton } from '../TaskButton'

export const IsSyncingAccountButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & LinkProps & { isDemo: boolean }
>(({ isLoading, ...props }, ref) => (
  <TaskButton
    w="100%"
    h="56px"
    px="16px"
    justifyContent="space-between"
    ref={ref}
    _hover={{ backgroundColor: 'gray.50' }}
    {...props}
  >
    <Flex gap="10px" align="center">
      <CircularProgress isIndeterminate color="primary.black" size="20px" />
      <Text fontSize="15px" fontWeight="500">
        {isLoading
          ? '別タブを開きます...'
          : '明細取得中につき、少々お待ちください'}
      </Text>
    </Flex>
  </TaskButton>
))
