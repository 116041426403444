import { useApiClient } from 'src/hooks/useApiClient'
import useSWR from 'swr'

export const usePollingATSyncingStatus = ({
  isPolling
}: {
  isPolling: boolean
}) => {
  const { apiClient } = useApiClient()
  const { data: accounts } = useSWR(
    'polling/accounts',
    async () => {
      const res = await apiClient.getAccounts()
      return res
    },
    // 1秒ごとにリフレッシュする
    { refreshInterval: isPolling ? 1000 : undefined }
  )

  return {
    syncingStatus:
      accounts === undefined
        ? undefined
        : accounts?.accounts.some((account) => account.at_status === 'S')
        ? ('syncing' as const)
        : accounts?.accounts.some((account) => account.at_status === '0')
        ? ('connected' as const)
        : ('notConnected' as const)
  }
}
