import useSWR from 'swr'
import { useNewApiClient } from '../useNewApiClient'

export const useTransactionsProgress = () => {
  const { apiClient } = useNewApiClient()
  const { data } = useSWR('/at_transactions/progress', async () => {
    const res = await apiClient.atTransaction.getAtTransactionsProgress()

    return res
  })

  return {
    transactionsProgress: data
  }
}
