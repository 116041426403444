import { createIcon } from '@chakra-ui/react'

export const CardIcon = createIcon({
  displayName: 'CardIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83334 5.41699V3.75033C5.83334 3.29009 6.20643 2.91699 6.66668 2.91699H17.5C17.9603 2.91699 18.3333 3.29009 18.3333 3.75033V11.2503C18.3333 11.7106 17.9603 12.0837 17.5 12.0837H16.6667"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.3333 7.91699H2.49999C2.03975 7.91699 1.66666 8.29009 1.66666 8.75033V16.2503C1.66666 16.7106 2.03975 17.0837 2.49999 17.0837H13.3333C13.7936 17.0837 14.1667 16.7106 14.1667 16.2503V8.75033C14.1667 8.29009 13.7936 7.91699 13.3333 7.91699Z"
        fill="#6E7DCA"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.66666 11.667H14.1667"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M14.1667 9.58301V14.583" stroke="#0D0D0D" strokeWidth="2" />
      <path d="M1.66666 9.58301V14.583" stroke="#0D0D0D" strokeWidth="2" />
      <path d="M4.58334 14.167H7.91668" stroke="#0D0D0D" strokeWidth="2" />
      <path d="M10.4167 14.167H11.25" stroke="#0D0D0D" strokeWidth="2" />
    </svg>
  )
})
