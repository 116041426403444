import { LockIcon } from '@chakra-ui/icons'
import { Button, Flex, FlexProps, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { TriangleUnderIcon } from 'src/components/common/Icons/TriangleUnderIcon'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useTaxReturnPageLink } from 'src/pages/tax_return/hooks/useTaxReturnPageLink'

type LinkButtonProps = {
  enableTaxReturn: boolean
}
const LinkButton: React.FC<LinkButtonProps> = ({ enableTaxReturn }) => {
  const router = useRouter()
  const { basic_information } = useTaxReturnPageLink()
  const commonProps = {
    gap: '8px',
    fontWeight: '500',
    fontSize: '16px',
    w: '320px',
    h: '56px'
  }

  return (
    <>
      {enableTaxReturn ? (
        <Button {...commonProps} onClick={() => router.push(basic_information)}>
          <Text>確定申告書の作成・提出へ</Text>
        </Button>
      ) : (
        <Button
          {...commonProps}
          variant={undefined}
          cursor="not-allowed"
          bgColor="gray.200"
          color="gray.500"
        >
          <LockIcon w="15px" h="20px" />
          <Text>確定申告書の作成・提出へ</Text>
        </Button>
      )}
    </>
  )
}

export const TaxReturnButton: React.FC<FlexProps> = (props) => {
  const { processing_year } = useProcessingYear()
  const enableTaxReturn =
    processing_year !== undefined &&
    (processing_year.processing_year === 2022 ||
      processing_year.processing_year === 2023)

  return (
    <>
      <Flex
        flexDir="column"
        w={['100%', '960px']}
        mt="13px"
        alignItems="center"
        gap="13px"
        {...props}
      >
        <TriangleUnderIcon width={'30px'} height={'12px'} />
        <LinkButton enableTaxReturn={enableTaxReturn} />
      </Flex>
    </>
  )
}
