import { Box, Button, Flex, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { HiddenInPC } from 'src/components/common/HiddenInPC'
import { ExternalLinkBoldIcon } from 'src/components/common/Icons/ExternalLinkBoldIcon'
import { useATLink } from 'src/hooks/useATLink'
import { useTaxTaskPageContext } from 'src/pages/tax_tasks/contexts/TaxTaskPageContext'
import { PAGE_PATH } from 'src/routes'
import { usePollingATSyncingStatus } from '../hooks/usePollingATSyncingStatus'
import { NextStepButton } from '../NextStepButton'

const passbook = (
  <svg
    width="60"
    height="36"
    viewBox="0 0 60 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.2768 0.820312L1.72103 0.820312C1.63151 0.820357 1.54287 0.838051 1.46018 0.872384C1.3775 0.906717 1.3024 0.957014 1.23917 1.0204C1.17594 1.08378 1.12583 1.15901 1.09171 1.24178C1.05758 1.32455 1.04011 1.41323 1.04028 1.50276L1.04028 31.5887C1.04028 32.5398 1.41807 33.4519 2.09055 34.1244C2.76302 34.797 3.67511 35.1748 4.62618 35.1749H55.3717C56.3228 35.1748 57.2348 34.797 57.9073 34.1244C58.5798 33.4519 58.9576 32.5398 58.9576 31.5887V1.50276C58.9578 1.41323 58.9403 1.32455 58.9062 1.24178C58.872 1.15901 58.8219 1.08378 58.7587 1.0204C58.6955 0.957014 58.6204 0.906717 58.5377 0.872384C58.455 0.838051 58.3664 0.820357 58.2768 0.820312Z"
      fill="#E50000"
    />
    <path
      d="M58.9583 5.50781L1.03931 5.50781L1.03931 14.9233L58.9583 14.9233V5.50781Z"
      fill="white"
    />
    <path
      d="M58.2767 0.00618075L3.01044 0.00618075C2.59222 0.00618075 2.17163 -0.00772594 1.75375 0.00618075C1.3513 0.00758295 0.965571 0.167313 0.67995 0.450838C0.39433 0.734364 0.231766 1.11891 0.227402 1.52133C0.218922 1.72484 0.227402 1.93073 0.227402 2.13458L0.227402 31.2745C0.221407 31.6461 0.246823 32.0174 0.30338 32.3847C0.446793 33.1455 0.787075 33.8553 1.29032 34.4436C1.79356 35.0319 2.44218 35.478 3.17155 35.7376C3.8817 35.9506 4.62421 36.035 5.36406 35.9869H54.3567C54.7148 35.9869 55.0737 35.9933 55.4315 35.9869C56.6043 35.9543 57.7171 35.4617 58.5295 34.6153C59.3419 33.7689 59.7887 32.6369 59.7732 31.4638C59.7806 29.0461 59.7732 26.6283 59.7732 24.2106V2.45986C59.7732 2.1858 59.7765 1.9114 59.7732 1.63767C59.783 1.43219 59.7521 1.22678 59.6822 1.03331C59.6123 0.839832 59.5047 0.662125 59.3657 0.510441C59.2267 0.358757 59.0591 0.236101 58.8725 0.149551C58.6858 0.0630023 58.4839 0.0142745 58.2784 0.00618075C58.063 0.00795328 57.857 0.0942883 57.7047 0.24657C57.5524 0.398851 57.4661 0.60488 57.4643 0.820231C57.4685 1.03483 57.5556 1.23947 57.7073 1.39124C57.8591 1.54301 58.0638 1.63011 58.2784 1.63428C58.1162 1.62139 58.179 1.42432 58.1451 1.57119C58.1357 1.6614 58.1357 1.75233 58.1451 1.84254V29.3737C58.1451 30.0911 58.1488 30.8081 58.1451 31.5255C58.1482 31.8978 58.0773 32.2671 57.9365 32.6117C57.7957 32.9564 57.5878 33.2697 57.3249 33.5333C57.062 33.7969 56.7493 34.0057 56.4049 34.1474C56.0606 34.2891 55.6916 34.3609 55.3193 34.3588C53.8085 34.3669 52.2975 34.3588 50.7867 34.3588H5.2545C4.50829 34.3588 3.82008 34.3493 3.14815 33.9321C2.74982 33.6783 2.42224 33.3277 2.19597 32.9132C1.9697 32.4986 1.85211 32.0334 1.85415 31.5611C1.85177 31.3576 1.85415 31.1558 1.85415 30.953L1.85415 1.94939C1.85415 1.88799 1.90129 1.54745 1.85415 1.50132C1.96133 1.60681 1.54548 1.63428 1.89078 1.63428L58.2767 1.63428C59.3244 1.63428 59.3261 0.00618075 58.2767 0.00618075Z"
      fill="#211715"
    />
    <path
      d="M1.04023 6.33123L58.9575 6.33123C60.0053 6.33123 60.007 4.70312 58.9575 4.70312L1.04023 4.70312C-0.00718322 4.70312 -0.00921836 6.33123 1.04023 6.33123Z"
      fill="#211715"
    />
    <path
      d="M1.03998 15.7453L58.9573 15.7453C60.005 15.7453 60.0067 14.1172 58.9573 14.1172L1.03998 14.1172C-0.00742736 14.1172 -0.00946251 15.7453 1.03998 15.7453Z"
      fill="#211715"
    />
    <path
      d="M42.9093 29.675H53.6442C54.6919 29.675 54.6936 28.0469 53.6442 28.0469H42.9093C41.8615 28.0469 41.8598 29.675 42.9093 29.675Z"
      fill="#211715"
    />
  </svg>
)

export const Accounts: React.FC = () => {
  const { isLoading, redirectToAT } = useATLink()
  const { stepIndex } = useTaxTaskPageContext()
  const { syncingStatus } = usePollingATSyncingStatus({
    isPolling: stepIndex === 1 || stepIndex === 2
  })
  const hasAccount =
    syncingStatus === 'connected' || syncingStatus === 'syncing'
  return (
    <Flex borderRadius="8px" flexDir="column" w="100%">
      <Box w={['100%', 'fit-content']}>
        <Text
          textAlign="center"
          fontSize="14px"
          fontWeight={700}
          lineHeight="19.6px"
          py="10px"
          bgColor="gray.200"
          borderTopRadius="8px"
        >
          {hasAccount ? (
            <>
              他にもクレカ・口座がある場合は
              <HiddenInPC>
                <br />
              </HiddenInPC>
              全て登録しましょう！
            </>
          ) : (
            'こんなクレカ・口座を連携しましょう！'
          )}
        </Text>
        <Flex
          bgColor="gray.70"
          px={['4px', '16px']}
          py="16px"
          gap={['4px', '12px']}
          borderBottomRadius="8px"
          justify="space-between"
        >
          {[
            {
              title: (
                <>
                  経費を
                  <br />
                  支払うクレカ
                </>
              ),
              image: (
                <svg
                  width="56"
                  height="36"
                  viewBox="0 0 56 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33338 2.18145L1.33338 33.8104C1.33311 33.9872 1.36773 34.1623 1.43525 34.3257C1.50277 34.4891 1.60186 34.6375 1.72685 34.7625C1.85184 34.8876 2.00027 34.9867 2.16364 35.0543C2.32701 35.1218 2.5021 35.1565 2.67889 35.1563C17.1637 35.1563 40.0557 35.1563 53.2948 35.1563C53.4714 35.1565 53.6463 35.122 53.8095 35.0545C53.9727 34.9871 54.121 34.8881 54.2459 34.7633C54.3709 34.6385 54.4699 34.4903 54.5375 34.3271C54.605 34.1639 54.6397 33.989 54.6396 33.8124V2.18347C54.6397 2.0065 54.605 1.83125 54.5373 1.66772C54.4697 1.50419 54.3705 1.3556 54.2453 1.23044C54.1202 1.10529 53.9717 1.00603 53.8081 0.938339C53.6446 0.870646 53.4694 0.835849 53.2924 0.835938L2.67686 0.835938C2.50026 0.835937 2.32539 0.870753 2.16226 0.938396C1.99913 1.00604 1.85093 1.10518 1.72615 1.23015C1.60137 1.35512 1.50246 1.50346 1.43506 1.66669C1.36766 1.82993 1.33311 2.00485 1.33338 2.18145Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M54.6407 7.03125V10.3937L1.3335 10.3937V7.03125L54.6407 7.03125Z"
                    fill="#211715"
                  />
                  <path
                    d="M10.9965 24.3201L10.9897 25.6535C10.9918 25.8683 11.0766 26.0742 11.2264 26.2282C11.3786 26.3804 11.5849 26.4658 11.8001 26.4658C12.0153 26.4658 12.2216 26.3804 12.3738 26.2282C12.5228 26.0736 12.6074 25.8682 12.6104 25.6535L12.6172 24.3201C12.6152 24.1053 12.5304 23.8994 12.3806 23.7454C12.2284 23.5933 12.022 23.5078 11.8069 23.5078C11.5917 23.5078 11.3853 23.5933 11.2332 23.7454C11.0841 23.9 10.9995 24.1055 10.9965 24.3201Z"
                    fill="#211715"
                  />
                  <path
                    d="M8.84868 24.3045L8.84192 25.6382C8.84402 25.8531 8.92876 26.0589 9.07857 26.2129C9.23073 26.3651 9.43709 26.4506 9.65227 26.4506C9.86745 26.4506 10.0738 26.3651 10.226 26.2129C10.375 26.0584 10.4596 25.8529 10.4626 25.6382L10.4694 24.3045C10.4673 24.0897 10.3825 23.8839 10.2327 23.7298C10.0806 23.5777 9.87421 23.4922 9.65903 23.4922C9.44385 23.4922 9.23749 23.5777 9.08533 23.7298C8.93657 23.8845 8.85201 24.0899 8.84868 24.3045Z"
                    fill="#211715"
                  />
                  <path
                    d="M6.70061 24.2967L6.69385 25.6301C6.69567 25.845 6.78045 26.0509 6.9305 26.2048C7.00584 26.2802 7.09531 26.34 7.19377 26.3808C7.29224 26.4216 7.39778 26.4426 7.50437 26.4426C7.61095 26.4426 7.71649 26.4216 7.81496 26.3808C7.91343 26.34 8.00289 26.2802 8.07824 26.2048C8.22705 26.0501 8.31163 25.8447 8.31488 25.6301L8.32165 24.2967C8.31955 24.0819 8.2348 23.876 8.085 23.722C7.93284 23.5698 7.72647 23.4844 7.5113 23.4844C7.29612 23.4844 7.08975 23.5698 6.93759 23.722C6.78858 23.8766 6.70397 24.082 6.70095 24.2967H6.70061Z"
                    fill="#211715"
                  />
                  <path
                    d="M4.55278 24.2811L4.54602 25.6144C4.54812 25.8293 4.63286 26.0351 4.78267 26.1892C4.93483 26.3413 5.14119 26.4268 5.35637 26.4268C5.57155 26.4268 5.77791 26.3413 5.93007 26.1892C6.07909 26.0346 6.1637 25.8291 6.16672 25.6144L6.17348 24.2811C6.17145 24.0662 6.0867 23.8604 5.93683 23.7064C5.78467 23.5542 5.57831 23.4688 5.36313 23.4688C5.14795 23.4688 4.94159 23.5542 4.78943 23.7064C4.64041 23.8609 4.5558 24.0664 4.55278 24.2811Z"
                    fill="#211715"
                  />
                  <path
                    d="M50.6236 17.5859H44.3274C43.2835 17.5859 43.2818 19.2087 44.3274 19.2087H50.6236C51.6679 19.2087 51.6696 17.5859 50.6236 17.5859Z"
                    fill="#211715"
                  />
                  <path
                    d="M41.0804 17.5859H34.7842C33.7399 17.5859 33.7382 19.2087 34.7842 19.2087H41.0804C42.1244 19.2087 42.126 17.5859 41.0804 17.5859Z"
                    fill="#211715"
                  />
                  <path
                    d="M31.5361 17.5859H25.2399C24.1959 17.5859 24.1943 19.2087 25.2399 19.2087H31.5361C32.5804 19.2087 32.5821 17.5859 31.5361 17.5859Z"
                    fill="#211715"
                  />
                  <path
                    d="M21.9921 17.5859H15.696C14.6517 17.5859 14.65 19.2087 15.696 19.2087H21.9921C23.0361 19.2087 23.0378 17.5859 21.9921 17.5859Z"
                    fill="#211715"
                  />
                  <path
                    d="M50.4393 28.6664C49.8177 29.4282 49.1955 30.1893 48.5728 30.9497C48.4262 31.1058 48.342 31.3103 48.3362 31.5244C48.3372 31.7395 48.4221 31.9457 48.5728 32.0991C48.7257 32.2507 48.9323 32.3358 49.1475 32.3358C49.2544 32.3372 49.3603 32.3169 49.4591 32.2763C49.5579 32.2356 49.6474 32.1753 49.7223 32.0991C50.3425 31.3365 50.9647 30.5754 51.5887 29.8158C51.7354 29.6597 51.8196 29.4552 51.8254 29.2411C51.8245 29.026 51.7396 28.8198 51.5887 28.6664C51.4359 28.5147 51.2293 28.4297 51.014 28.4298C50.9072 28.4284 50.8012 28.4486 50.7025 28.4893C50.6037 28.53 50.5142 28.5902 50.4393 28.6664Z"
                    fill="#211715"
                  />
                  <path
                    d="M49.5777 24.0634C47.7957 26.2466 46.005 28.4245 44.2261 30.6091C44.0796 30.7642 43.998 30.9694 43.998 31.1828C43.998 31.3961 44.0796 31.6013 44.2261 31.7565C44.379 31.9071 44.5851 31.9916 44.7998 31.9916C45.0145 31.9916 45.2205 31.9071 45.3735 31.7565C47.1524 29.5705 48.9431 27.3954 50.7251 25.2108C50.8717 25.0557 50.9534 24.8505 50.9534 24.6371C50.9534 24.4237 50.8717 24.2184 50.7251 24.0634C50.5722 23.9126 50.3661 23.8281 50.1514 23.8281C49.9367 23.8281 49.7306 23.9126 49.5777 24.0634Z"
                    fill="#211715"
                  />
                  <path
                    d="M1.33353 7.84147L54.6408 7.84147C55.6847 7.84147 55.6868 6.21875 54.6408 6.21875L1.33353 6.21875C0.289572 6.21875 0.287882 7.84147 1.33353 7.84147Z"
                    fill="#211715"
                  />
                  <path
                    d="M1.3334 11.2008L54.6406 11.2008C55.6846 11.2008 55.6866 9.57812 54.6406 9.57812L1.3334 9.57812C0.28945 9.57812 0.28776 11.2008 1.3334 11.2008Z"
                    fill="#211715"
                  />
                  <path
                    d="M0.522011 2.18158L0.522011 32.6273C0.522011 33.3971 0.420591 34.2223 0.826272 34.915C1.62749 36.2872 3.41892 35.9678 4.76037 35.9678H51.8438C52.476 36.0111 53.1109 35.9949 53.74 35.9194C54.1293 35.8362 54.4882 35.6475 54.7773 35.3738C55.0664 35.1002 55.2746 34.7522 55.379 34.368C55.574 33.6202 55.451 32.727 55.451 31.9596V3.00781C55.4869 2.54651 55.4626 2.0825 55.3786 1.62748C55.2739 1.24284 55.0654 0.894381 54.7761 0.620136C54.4868 0.345892 54.1277 0.156409 53.738 0.0723697C53.102 -0.00362889 52.4602 -0.0198161 51.8211 0.0240242L3.40201 0.0240242C2.80769 0.0240242 2.21404 -0.00977914 1.66941 0.275888C1.32662 0.463401 1.03977 0.738571 0.838174 1.07328C0.636579 1.40798 0.527474 1.79021 0.522011 2.1809C0.477724 3.22519 2.10045 3.22316 2.14474 2.1809C2.16637 1.66974 2.61127 1.64675 3.01662 1.64675L43.9658 1.64675C47.0361 1.64675 50.1075 1.61937 53.1775 1.64675C53.6967 1.65115 53.8283 1.94662 53.8283 2.43006V33.0252C53.8283 33.3295 53.9138 33.7619 53.7816 34.0414C53.5788 34.4701 52.9861 34.343 52.6031 34.343H3.16232C2.74988 34.343 2.21776 34.3978 2.14812 33.8667C2.12349 33.4976 2.1227 33.1273 2.14575 32.7581L2.14575 2.18158C2.14474 1.13762 0.522011 1.13559 0.522011 2.18158Z"
                    fill="#211715"
                  />
                  <path
                    d="M3.68876 4.28565L5.28849 5.20925C5.33592 5.23644 5.38966 5.25069 5.44433 5.25057C5.499 5.25046 5.55268 5.23598 5.59999 5.20859C5.64731 5.1812 5.68659 5.14186 5.71392 5.09451C5.74125 5.04716 5.75566 4.99347 5.7557 4.9388C5.7557 4.32081 5.7557 3.70507 5.7557 3.09159C5.75558 3.03695 5.74107 2.98332 5.71363 2.93607C5.68619 2.88882 5.64679 2.84964 5.59939 2.82246C5.55199 2.79528 5.49827 2.78107 5.44363 2.78125C5.389 2.78144 5.33537 2.79601 5.28816 2.82351L3.68909 3.74677C3.64193 3.77416 3.60277 3.81346 3.57554 3.86072C3.54832 3.90799 3.53397 3.96157 3.53394 4.01611C3.5339 4.07066 3.54818 4.12426 3.57535 4.17156C3.60252 4.21886 3.64162 4.2582 3.68876 4.28565Z"
                    fill="#211715"
                  />
                  <path
                    d="M10.9599 15.0078H6.26347C6.14849 15.0078 6.03463 15.0305 5.92842 15.0745C5.8222 15.1185 5.72571 15.1831 5.64445 15.2644C5.56319 15.3458 5.49877 15.4424 5.45486 15.5486C5.41095 15.6549 5.38842 15.7688 5.38855 15.8837V19.9852C5.38846 20.1001 5.41103 20.214 5.45496 20.3202C5.49888 20.4264 5.56332 20.523 5.64457 20.6043C5.72582 20.6856 5.8223 20.7501 5.9285 20.7941C6.03469 20.8381 6.14852 20.8608 6.26347 20.8608H10.9596C11.0746 20.8609 11.1884 20.8384 11.2947 20.7945C11.401 20.7506 11.4975 20.6861 11.5789 20.6049C11.6602 20.5236 11.7248 20.4271 11.7688 20.3209C11.8128 20.2147 11.8355 20.1008 11.8355 19.9859V15.8834C11.8355 15.6512 11.7433 15.4285 11.5791 15.2643C11.4148 15.1001 11.1921 15.0078 10.9599 15.0078Z"
                    fill="#FFDA71"
                  />
                  <path
                    d="M11.1458 14.1978H6.58358C6.27171 14.1726 5.95784 14.1938 5.6522 14.2607C5.35822 14.3517 5.09868 14.5296 4.90776 14.771C4.71683 15.0124 4.60346 15.3059 4.58255 15.613C4.51933 16.4642 4.57714 17.3388 4.57714 18.1914V19.7675C4.57714 20.2905 4.59844 20.7925 4.98857 21.2049C5.44226 21.6853 6.03658 21.6742 6.64679 21.6742H10.3216C10.9528 21.6742 11.5968 21.7462 12.1134 21.3135C12.6691 20.8479 12.6468 20.1978 12.6468 19.5356V16.4669C12.6761 16.0961 12.662 15.7232 12.6046 15.3557C12.5213 15.0299 12.3336 14.7403 12.0701 14.5314C11.8067 14.3224 11.482 14.2054 11.1458 14.1985C10.9306 14.1985 10.7242 14.284 10.5721 14.4361C10.4199 14.5883 10.3344 14.7947 10.3344 15.0098C10.3344 15.225 10.4199 15.4314 10.5721 15.5836C10.7242 15.7357 10.9306 15.8212 11.1458 15.8212C11.0819 15.8175 11.0782 15.7844 11.0302 15.7576C11.185 15.8445 11.0265 15.8121 11.0319 15.7455C11.0461 15.5683 11.0241 15.9733 11.0241 15.9554V19.4078C11.0241 19.4754 11.0495 20.1681 11.0241 20.1742C10.9987 20.1803 11.0917 20.0518 11.0877 20.0579C10.9592 20.2878 11.162 19.9808 11.1458 20.0515C11.1367 20.0917 9.86115 20.0515 9.75127 20.0515H6.33036C6.34727 20.0515 5.94733 20.0744 6.12313 20.0596C6.19074 20.0538 6.22455 20.2127 6.13597 20.0575C6.13969 20.064 6.23536 20.159 6.19987 20.1721C6.21797 19.9595 6.21797 19.7458 6.19987 19.5332V16.6934C6.22235 16.3874 6.22235 16.0802 6.19987 15.7742C6.20173 15.75 6.20173 15.7257 6.19987 15.7015C6.21643 15.7083 6.17384 15.7813 6.16978 15.7857C6.11637 15.8346 6.12425 15.8255 6.19344 15.7583C6.17691 15.7841 6.1523 15.8037 6.12346 15.8141C5.94733 15.7992 6.3476 15.8219 6.33036 15.8219H11.1458C12.1901 15.8205 12.1918 14.1978 11.1458 14.1978Z"
                    fill="#211715"
                  />
                </svg>
              )
            },
            {
              title: (
                <>
                  左のクレカが
                  <br />
                  引き落される口座
                </>
              ),
              image: passbook
            },
            {
              title: (
                <>
                  売上が
                  <br />
                  入金される
                  <HiddenInPC>
                    <br />
                  </HiddenInPC>
                  口座
                </>
              ),
              image: passbook
            }
          ].map(({ title, image }, i, arr) => (
            <Flex
              flexDir="column"
              align="center"
              w={[`calc(100% / ${arr.length})`, '124px']}
              py="16px"
              key={i}
              borderRadius="16px"
              border="1px solid"
              borderColor="gray.100"
              bgColor="white.100"
            >
              {image}
              <Text
                mt="9px"
                fontSize="13px"
                fontWeight={700}
                lineHeight="19.5px"
                textAlign="center"
              >
                {title}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Text fontSize="11px" fontWeight={500} lineHeight="15px">
          ※金融機関によっては最短1ヶ月分までしか連携できないクレカ・口座もございます。
          <br />
          連携されなかった取引は
          <Link href={PAGE_PATH.journals.index}>こちら</Link>
          から手動で登録してください。
        </Text>
      </Box>
      <Button
        color={hasAccount ? 'primary.black' : 'white.100'}
        mt={['12px', '16px']}
        variant={hasAccount ? 'outline' : 'primary'}
        h={hasAccount ? '30px' : '48px'}
        minH="30px"
        w={hasAccount ? '248px' : '276px'}
        fontWeight={500}
        fontSize={hasAccount ? '13px' : '14px'}
        lineHeight={1}
        gap={hasAccount ? '6px' : '10px'}
        isLoading={isLoading}
        onClick={() => redirectToAT()}
      >
        <Text>クレカ・口座を連携（外部サイト）</Text>
        <ExternalLinkBoldIcon
          height={hasAccount ? '16px' : '20px'}
          width={hasAccount ? '16px' : '20px'}
          stroke={hasAccount ? 'primary.black' : 'white.100'}
        />
      </Button>
      {hasAccount && <NextStepButton mt="12px" />}
    </Flex>
  )
}
