import { LockIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  chakra,
  Flex,
  Image,
  Text,
  useBoolean
} from '@chakra-ui/react'
import { isBefore } from 'date-fns'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { AlertMessage } from 'src/components/common/AlertMessage'
import { CompleteCheckCircleIcon } from 'src/components/common/Icons/CompleteCheckCircleIcon'
import { useTransactionsProgress } from 'src/hooks/at_transactions/useTransactionsProgress'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useTaxReturnQuestion } from 'src/hooks/useTaxReturnQuestion'
import { useTaxReturnPageLink } from 'src/pages/tax_return/hooks/useTaxReturnPageLink'

export const HAS_FINAL_CHECK_LOCAL_STORAGE_KEY = 'has_final_check_once'

export const FinalCheck: React.FC = () => {
  const router = useRouter()
  const { final_check } = useTaxReturnPageLink()
  const [
    /**
     * 最終チェックページで「チェックを完了」ボタンを一度でも押したことがあるかどうか
     * 現在最終チェックが完了しているかどうかは関係ない
     */
    hasFinalCheckOnce,
    { on: setHasFinalCheckOnce }
  ] = useBoolean()
  const { processing_year } = useProcessingYear()
  const isBeforeTaxReturnPeriod = isBefore(
    new Date(),
    processing_year
      ? new Date(`${processing_year?.processing_year + 1}/1/1`)
      : new Date()
  )
  const { transactionsProgress } = useTransactionsProgress()
  /**
   *  すべてのカテゴリ登録が完了しているか
   */
  const isAllTransactionRegistered = !!transactionsProgress?.is_finished

  const { questions } = useTaxReturnQuestion()
  /**
   * サーバー上で最終チェックが終わっているか
   */
  const checked = !!questions?.business_income.checked_business_income

  useEffect(() => {
    const flag = localStorage.getItem(HAS_FINAL_CHECK_LOCAL_STORAGE_KEY)
    if (flag === 'true') {
      setHasFinalCheckOnce()
    }
  }, [])
  return (
    <Flex align="flex-start" flexDir="column" mt="6px" gap="16px">
      {
        // その会計年度の確定申告期間より前
        isBeforeTaxReturnPeriod ? (
          <>
            <Text fontSize="13px" fontWeight={400} lineHeight="20px">
              登録内容のチェックにお進みください。
              <br />
              チェックが完了すると「確定申告書の作成」に進む事ができます。
            </Text>
            <Button
              gap="8px"
              cursor="not-allowed"
              bgColor="gray.200"
              color="gray.500"
            >
              <LockIcon w="20px" h="20px" />
              <Text>最終チェックする</Text>
            </Button>
          </>
        ) : // カテゴリ登録は完了しているが、サーバー上でチェック未完了
        isAllTransactionRegistered && !checked ? (
          <Button onClick={() => router.push(final_check)}>
            <Text>最終チェックする</Text>
          </Button>
        ) : // カテゴリ登録は完了していて、サーバー上でもチェック完了
        isAllTransactionRegistered && checked ? (
          <>
            <Text fontSize="13px" fontWeight={400} lineHeight="20px" mb="16px">
              チェックが完了しました。
              <br />
              <chakra.span fontWeight={700}>
                画面下の「確定申告書の作成へ」
              </chakra.span>
              より先にお進みください！
            </Text>
            <Button
              variant="outline"
              gap="8px"
              color="gray.800"
              bgColor="gray.70"
              px="12px"
              borderColor="gray.200"
              onClick={() => router.push(final_check)}
            >
              <CompleteCheckCircleIcon w="20px" h="20px" />
              <Text>最終チェック済み</Text>
            </Button>
          </>
        ) : (
          <>
            <AlertMessage
              title={
                // カテゴリ登録は完了していなくて
                // ユーザー自身で一度でも「チェックを完了」ボタンを押したことがない
                !hasFinalCheckOnce ? (
                  <>
                    3つ目のステップ「収入・経費の登録」が完了していません。
                    <br />
                    まずは「収入・経費の登録」を完了させましょう。
                  </>
                ) : (
                  // カテゴリ登録は完了していなくて
                  // ユーザー自身で一度でも「チェックを完了」ボタンを押したことがある
                  // その後新しくクレカの履歴が追加され、サーバー上でチェックが未完了になった
                  <>
                    新しい口座・クレカの履歴が追加されました。
                    <br />
                    3つ目のステップ「収入・経費の登録」を完了させて、
                    <br />
                    もう一度最終チェックを行いましょう。
                  </>
                )
              }
              content={
                <Box px="30px" bgColor="gray.250">
                  <Image
                    w="180px"
                    h="180px"
                    src="/images/tax_tasks/zeione_step.gif"
                  />
                </Box>
              }
            />
            <Button variant="outline" onClick={() => router.push(final_check)}>
              最終チェックする
            </Button>
          </>
        )
      }
    </Flex>
  )
}
