import { createIcon } from '@chakra-ui/react'

export const HospitalIcon = createIcon({
  displayName: 'HospitalIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.58335 17.5H7.91669H6.25002H3.75002C3.28978 17.5 2.91669 17.1269 2.91669 16.6667V3.33333C2.91669 2.8731 3.28978 2.5 3.75002 2.5H15.4167C15.8769 2.5 16.25 2.8731 16.25 3.33333V6.25V8.125"
          stroke="#0D0D0D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M15.2652 11.25C16.2693 11.25 17.0834 12.0335 17.0834 13C17.0834 14.2582 15.8712 15.3333 15.2652 15.9167C14.8611 16.3055 14.3561 16.6945 13.75 17.0833C13.144 16.6945 12.6389 16.3055 12.2349 15.9167C11.6288 15.3333 10.4167 14.2582 10.4167 13C10.4167 12.0335 11.2307 11.25 12.2349 11.25C12.8673 11.25 13.4243 11.5608 13.75 12.0323C14.0758 11.5608 14.6328 11.25 15.2652 11.25Z"
          fill="#6E7DCA"
          stroke="#0D0D0D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M6.25 5.83398H12.9167"
          stroke="#0D0D0D"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </svg>
    </>
  )
})
