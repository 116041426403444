import { Text, HStack, CircularProgress } from '@chakra-ui/react'

type Props = {
  text?: string
}

export const IsSyncingAccountLabel = ({ text = '取得中' }: Props) => {
  return (
    <HStack bgColor="primary.purple" borderRadius="8px" py="8px" px="10px">
      <CircularProgress
        isIndeterminate
        thickness="16px"
        color="white.100"
        trackColor="primary.purple"
        borderColor="primary.purple"
        size="12px"
      />
      <Text fontSize="12px" color="white" lineHeight="12px" fontWeight="700">
        {text}
      </Text>
    </HStack>
  )
}
