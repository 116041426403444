import { request_taxReturnQuestions } from 'src/api'
import useSWR from 'swr'
import { useNewApiClient } from './useNewApiClient'

export const useTaxReturnQuestion = () => {
  const { apiClient } = useNewApiClient()
  const { data, mutate } = useSWR('/tax_return_questions', async () => {
    const res = await apiClient.taxReturn.getTaxReturnQuestions()
    return res
  })

  const patchTaxReturnQuestions = async (data: request_taxReturnQuestions) => {
    await apiClient.taxReturn.patchTaxReturnQuestions(data)
    await mutate()
  }

  return {
    questions: data,
    patchTaxReturnQuestions
  }
}
