import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TabPanelProps,
  Text
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { response_task } from 'src/api'
import { TrashIcon } from 'src/components/common/Icons/TrashIcon'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useTasks } from 'src/hooks/useTasks'
import { PAGE_PATH } from 'src/routes'
import { MonthlyTaskCardButton } from '../MonthlyTaskCardButton'
import { MonthlyTasksTabPanelSubTabPanel } from '../MonthlyTasksTabPanelSubTabPanel'

const getMonthFromInvoiceTaskName = (task?: response_task) => {
  return task?.code ? Number(task.code.match(/invoice_(\d+)/)?.[1]) : 1
}

type Props = {
  invoice: response_task[]
  isDemo: boolean
}

export const InvoicePanel: React.FC<TabPanelProps & Props> = ({
  invoice,
  isDemo,
  ...props
}) => {
  const { deleteInvoiceTask } = useTasks()
  const { processing_year } = useProcessingYear()

  const router = useRouter()
  const [deleteItem, setDeleteItem] = useState<response_task>()
  const [selectedInvoice, setSelectedInvoice] = useState<response_task>()

  const closeDeleteModal = () => {
    setDeleteItem(undefined)
    setSelectedInvoice(undefined)
  }
  const closeSelectModal = () => {
    setSelectedInvoice(undefined)
  }

  const deleteTask = async () => {
    if (!processing_year || !deleteItem) return
    const month = getMonthFromInvoiceTaskName(deleteItem)
    await deleteInvoiceTask({
      year: processing_year.processing_year,
      month
    })
    closeDeleteModal()
  }

  return (
    <MonthlyTasksTabPanelSubTabPanel {...props}>
      <Flex flexDir="column" gap="8px">
        {invoice
          .map((task, i) => {
            const month = getMonthFromInvoiceTaskName(task)
            const button = (
              <MonthlyTaskCardButton
                key={i}
                month={month}
                task={task}
                pos="relative"
                isDemo={isDemo}
                onClick={() =>
                  isMobile
                    ? router.push(PAGE_PATH.invoices.index)
                    : setSelectedInvoice(task)
                }
              />
            )
            return isMobile || isDemo ? (
              button
            ) : (
              <Box key={i}>
                <Popover trigger="hover" placement="right" closeDelay={200}>
                  <PopoverTrigger>{button}</PopoverTrigger>
                  <PopoverContent bgColor="white.100" w="auto">
                    <Popover trigger="hover" placement="top" closeDelay={200}>
                      <PopoverTrigger>
                        <IconButton
                          _focus={{
                            bosShadow: 'none'
                          }}
                          variant="ghost"
                          aria-label="請求書タスクの削除"
                          icon={<TrashIcon />}
                          onClick={() => setDeleteItem(task)}
                        />
                      </PopoverTrigger>
                      <PopoverContent
                        bgColor="white.100"
                        w="auto"
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="2px"
                      >
                        <Text
                          fontSize="12px"
                          lineHeight={1}
                          py="10px"
                          px="12px"
                        >
                          削除
                        </Text>
                      </PopoverContent>
                    </Popover>
                  </PopoverContent>
                </Popover>
              </Box>
            )
          })
          .reverse()}
      </Flex>
      {/* 請求書発行確認モーダル */}
      <Modal isOpen={!!selectedInvoice} onClose={closeSelectModal} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text
              mt="10px"
              fontWeight={700}
              fontSize="16px"
              lineHeight="25.6px"
            >
              {`${getMonthFromInvoiceTaskName(
                selectedInvoice
              )}月分の請求書を発行しますか？`}
            </Text>
            <Flex gap="16px" mt="20px" mb="7px">
              <Button
                variant="outline"
                size="lg"
                w="178px"
                color="primary.black"
                onClick={() => {
                  closeSelectModal()
                  setDeleteItem(selectedInvoice)
                }}
              >
                発行しない
              </Button>
              <Button
                onClick={() => router.push(PAGE_PATH.invoices.index)}
                w="178px"
                size="lg"
              >
                発行する
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* 削除 */}
      <Modal isOpen={!!deleteItem} onClose={closeDeleteModal} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text fontWeight={700} fontSize="15px" lineHeight="24px" mt="10px">
              {`タスク「請求書発行 ${getMonthFromInvoiceTaskName(
                deleteItem
              )}月分」を削除しますか？`}
              <br />
              この操作は取り消せません！
            </Text>
          </ModalBody>

          <ModalFooter backgroundColor="gray.70">
            <Button
              variant="outline"
              color="gray.800"
              fontSize="13px"
              mr="12px"
              size="sm"
              onClick={closeDeleteModal}
            >
              キャンセル
            </Button>
            <Button
              fontSize="13px"
              onClick={deleteTask}
              size="sm"
              bgColor="primary.alert"
            >
              削除
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MonthlyTasksTabPanelSubTabPanel>
  )
}
