import { Button, ButtonProps, LinkProps } from '@chakra-ui/react'
import React from 'react'
import { WithUnclickableTooltip } from 'src/pages/demo/components/UnclickableTooltip'
import { WithEncourageConnectionTooltip } from '../EncourageConnectionTooltip'

export const TaskButton = React.memo(
  React.forwardRef<
    HTMLButtonElement,
    ButtonProps &
      LinkProps & {
        isDemo: boolean
        isNotConnectedOnFreeTrial?: boolean
        linkType?: string
      }
  >(
    (
      { children, href, isDemo, isNotConnectedOnFreeTrial, linkType, ...props },
      ref
    ) => {
      const isUnclickable = isDemo || isNotConnectedOnFreeTrial

      const _ButtonComponent = (
        <Button
          cursor={isUnclickable ? 'not-allowed' : 'pointer'}
          opacity={isUnclickable ? '0.4' : '1'}
          ref={ref}
          variant="outline"
          as="a"
          target={linkType && linkType === 'internal' ? '_self' : '_blank'}
          rel={linkType && linkType === 'internal' ? '' : 'noreferrer noopener'}
          display={'flex'}
          alignItems={'center'}
          color={'black.standard'}
          h={'68px'}
          backgroundColor={'white'}
          _hover={{ backgroundColor: 'gray.50' }}
          borderColor={'gray.200'}
          borderWidth={'1px'}
          borderRadius="4px"
          boxShadow="0px 2px 6px 0px #00000005"
          {...props}
          onClick={isUnclickable ? (e) => e.preventDefault() : props.onClick}
          {...(!isUnclickable && { href })}
          w={isUnclickable ? '100%' : props.w}
        >
          {children}
        </Button>
      )
      return isDemo
        ? WithUnclickableTooltip(isDemo, _ButtonComponent, props)
        : isNotConnectedOnFreeTrial
        ? WithEncourageConnectionTooltip(true, _ButtonComponent, props)
        : _ButtonComponent
    }
  )
)
