import { createIcon } from '@chakra-ui/react'

export const OpeningNotificationIcon = createIcon({
  displayName: 'OpeningNotificationIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M17.5 5V2H4C3.72386 2 3.5 2.22386 3.5 2.5V19H6.5"
        fill="#6E7DCA"
      />
      <path
        d="M17.5 5V2H4C3.72386 2 3.5 2.22386 3.5 2.5V19H6.5"
        stroke="#0D0D0D"
        strokeWidth="2"
      />
      <path
        d="M20.5 5H6.5V22H20.5V5Z"
        fill="white"
        stroke="#0D0D0D"
        strokeWidth="2"
      />
      <path d="M10.5 11H16.5" stroke="#0D0D0D" strokeWidth="2" />
      <path d="M10.5 15H16.5" stroke="#0D0D0D" strokeWidth="2" />
    </svg>
  )
})
