import { EditIcon } from '@chakra-ui/icons'
import { Flex, TabPanelProps } from '@chakra-ui/react'
import NextLink from 'next/link'
import React from 'react'
import { response_task } from 'src/api'
import { IconTaskCardButton } from '../IconTaskCardButton'
import { MonthlyTasksTabPanelSubTabPanel } from '../MonthlyTasksTabPanelSubTabPanel'
import { allFixedTasks } from './DocumentPanel'

type Props = {
  tax: response_task[]
  isDemo: boolean
}
export const SaveTaxPanel: React.FC<TabPanelProps & Props> = ({
  tax,
  isDemo,
  ...props
}) => {
  return (
    <MonthlyTasksTabPanelSubTabPanel {...props}>
      <Flex flexDir="column" gap="8px">
        {tax
          .map((task, i) => (
            <NextLink href={task.url || ''} passHref key={i}>
              <IconTaskCardButton
                icon={
                  allFixedTasks.find(({ label }) => label === task.name)
                    ?.icon || <EditIcon w="20px" h="20px" />
                }
                pos="relative"
                task={task}
                isDemo={isDemo}
              />
            </NextLink>
          ))
          .reverse()}
      </Flex>
    </MonthlyTasksTabPanelSubTabPanel>
  )
}
