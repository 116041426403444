import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text
} from '@chakra-ui/react'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useEffectOnce } from 'react-use'
import { useTasks } from 'src/hooks/useTasks'
import { TaxChart } from '../TaxChart'
import { TaxReturnButton } from '../TaxReturnButton'
import { EntireFlowTabPanel } from './EntireFlowTabPanel'
import { MonthlyTasksTabPanel } from './MonthlyTasksTabPanel'

const TaskListTab: React.FC<TabProps> = ({ children, ...props }) => {
  return (
    <Tab
      borderRadius="6px"
      w="120px"
      h="42px"
      fontWeight={500}
      lineHeight={1}
      fontSize="15px"
      color="gray.600"
      bgColor="transparent"
      _selected={{
        color: 'white',
        fontWeight: 700,
        bgColor: 'primary.black'
      }}
      _focus={{
        boxShadow: 'none'
      }}
      px="0"
      textAlign="center"
      pos="relative"
      {...props}
    >
      {children}
    </Tab>
  )
}

const TASK_LIST_TAB_INDEX_STORAGE_KEY = 'zayone-task-list-tab-index'

export const TaskListTabs: React.VFC = () => {
  const { postDevData } = useTasks()

  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
    localStorage.setItem(TASK_LIST_TAB_INDEX_STORAGE_KEY, String(index))
  }

  useEffectOnce(() => {
    const tabIndexInStorage = Number(
      localStorage.getItem(TASK_LIST_TAB_INDEX_STORAGE_KEY)
    )
    setTabIndex(
      isMobile
        ? 1
        : Number.isSafeInteger(tabIndexInStorage)
        ? tabIndexInStorage
        : 0
    )
  })

  return (
    <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
      <TabList
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8px"
        bgColor="gray.70"
        p="4px"
        display="flex"
        w="fit-content"
        mb="20px"
      >
        <TaskListTab>全体像</TaskListTab>
        <TaskListTab>今月やること</TaskListTab>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <Text mb="16px" fontSize="20px" fontWeight={700}>
            上から順に進めましょう
          </Text>
          <EntireFlowTabPanel />
          <TaxReturnButton />
        </TabPanel>
        <TabPanel p={0}>
          <Text mb="16px" fontSize="20px" fontWeight={700}>
            今月やるべきことはこちらです
          </Text>
          <Flex gap="28px">
            <MonthlyTasksTabPanel />
            <Box>
              <TaxChart isDemo={false} />
            </Box>
          </Flex>
        </TabPanel>
      </TabPanels>
      <Box mt="16px">
        {(process.env.NEXT_PUBLIC_ENV === 'local' ||
          process.env.NEXT_PUBLIC_ENV === 'development') && (
          <>
            <Button
              onClick={postDevData}
              pos="absolute"
              left="0px"
              bottom="0px"
            >
              モックデータ作成
            </Button>
          </>
        )}
      </Box>
    </Tabs>
  )
}
