import { Button, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import { CautionIcon } from 'src/components/common/Icons/CautionIcon'
import { ItemOfAccount } from 'src/hooks/useAccounts'
import { useATLink } from 'src/hooks/useATLink'

type Props = {
  accounts: ItemOfAccount[]
}

export const AccountConnectFailed: React.VFC<Props> = ({ accounts }) => {
  const { isLoading, redirectToATByAccountId, redirectToAT } = useATLink()

  const accountId = accounts.length === 1 ? accounts[0].account_id : null
  const redirectToAccountTracker = () => {
    accountId ? redirectToATByAccountId(accountId) : redirectToAT()
  }

  return (
    <HStack
      backgroundColor="cost.100"
      border="1px solid"
      borderColor="#E0B3B2"
      borderRadius="2px"
      justifyContent="space-between"
      mt="0"
      mb="15px"
      paddingX="16px"
      paddingY="4px"
    >
      <VStack alignItems="flex-start" spacing={0}>
        <HStack alignItems="center">
          <CautionIcon height={'20px'} width={'20px'} />
          <Text fontSize="13px" fontWeight="700" lineHeight="1.6">
            連携の有効期限が切れました。
            <br />
            再連携してください。
          </Text>
        </HStack>
        <VStack alignItems="flex-start" spacing={0} paddingLeft="28px">
          {accounts.map((account, i) => {
            if (i < 2) {
              return (
                <Text key={i} fontSize="13px" fontWeight="500" lineHeight="1.6">
                  ・{account.name}
                  {accounts.length > 2 &&
                    i === 1 &&
                    ' 他' + (accounts.length - 2) + '件'}
                  {/* 3件以上の場合は「他○件」を末尾表示 */}
                </Text>
              )
            }
          })}
        </VStack>
      </VStack>
      <HStack>
        {isLoading && <Spinner w="16px" h="16px" />}
        <Button
          backgroundColor="transparent"
          border="1px solid"
          borderColor="#E0B3B2"
          borderRadius="4px"
          color="primary.black"
          disabled={isLoading}
          fontSize="13px"
          fontWeight="500"
          height="30px"
          onClick={redirectToAccountTracker}
          minHeight="auto"
          paddingLeft="12px"
          paddingRight="12px"
        >
          再連携
        </Button>
      </HStack>
    </HStack>
  )
}
