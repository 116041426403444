import { createIcon } from '@chakra-ui/react'

export const TriangleUnderIcon = createIcon({
  displayName: 'TriangleUnderIcon',
  viewBox: '0 0 16 16',
  path: (
    <g id="Ic/Line/down-one">
      <path
        id="Vector"
        d="M12 6.3335L8 10.3335L4 6.3335H12Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="bevel"
      />
    </g>
  )
})
