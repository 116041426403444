import {
  Box,
  ButtonProps,
  chakra,
  Flex,
  LinkProps,
  Text
} from '@chakra-ui/react'
import { format, isAfter } from 'date-fns'
import React, { useMemo } from 'react'
import { response_task } from 'src/api'
import { TaskButton } from '../TaskButton'

type Props = {
  month: number
  task: response_task
}
export const MonthlyTaskCardButton = React.forwardRef<
  HTMLButtonElement,
  Props &
    ButtonProps &
    LinkProps & { isDemo: boolean; isNotConnectedOnFreeTrial?: boolean }
>(({ month, task, ...props }, ref) => {
  const isRequired = task.task_type === response_task.task_type.REQUIRED
  const expirationDateObject = useMemo(
    () =>
      task.expiration_date ? new Date(task.expiration_date) : new Date(''),
    [task]
  )

  return (
    <TaskButton
      w={['100%', '240px']}
      h="64px"
      px={['12px', '16px']}
      justifyContent="space-between"
      ref={ref}
      linkType={task.url_type}
      {...props}
    >
      <Flex
        gap="10px"
        align="center"
        justify={['space-between', 'flex-start']}
        w={['100%', 'fit-content']}
      >
        <Flex alignItems={'baseline'}>
          <chakra.span
            fontSize={'30px'}
            lineHeight="26px"
            fontWeight={'800'}
            fontFamily={'Open Sans'}
          >
            {month}
          </chakra.span>
          <chakra.span marginLeft="4px" fontSize="12px" fontWeight={500}>
            月
          </chakra.span>
        </Flex>
        <Box
          bgColor={isRequired ? 'primary.purple' : 'white.100'}
          color={isRequired ? 'white.100' : 'gray.800'}
          borderRadius="2px"
          border={isRequired ? 'none' : '1px solid'}
          borderColor="gray.200"
          fontSize="11px"
          lineHeight={1}
          fontWeight={500}
          py="3.5px"
          px="5px"
        >
          {isRequired ? '必須' : '推奨'}
        </Box>
      </Flex>
      <Text
        display={['none', 'block']}
        fontSize="13px"
        fontWeight={400}
        color={
          isAfter(new Date(), expirationDateObject)
            ? 'primary.alert'
            : 'inherit'
        }
      >
        {task.expiration_date &&
          `~ ${format(expirationDateObject, 'yyyy/MM/dd')}`}
      </Text>
    </TaskButton>
  )
})
