import { createIcon } from '@chakra-ui/react'

export const BuildingIcon = createIcon({
  displayName: 'BuildingIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        d="M1.66669 17.5H18.3334"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.49998 9.16602H4.16665C3.70641 9.16602 3.33331 9.53911 3.33331 9.99935V16.666C3.33331 17.1263 3.70641 17.4993 4.16665 17.4993H7.49998C7.96022 17.4993 8.33331 17.1263 8.33331 16.666V9.99935C8.33331 9.53911 7.96022 9.16602 7.49998 9.16602Z"
        fill="#6E7DCA"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.8333 1.66602H9.16665C8.70641 1.66602 8.33331 2.03911 8.33331 2.49935V16.666C8.33331 17.1263 8.70641 17.4993 9.16665 17.4993H15.8333C16.2936 17.4993 16.6666 17.1263 16.6666 16.666V2.49935C16.6666 2.03911 16.2936 1.66602 15.8333 1.66602Z"
        fill="#6E7DCA"
        stroke="#0D0D0D"
        strokeWidth="1.5"
      />
      <path
        d="M11.6667 13.3359H13.3334"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5 13.3359H6.66667"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M11.6667 9.58594H13.3334"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M11.6667 5.83594H13.3334"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </>
  )
})
