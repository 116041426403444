import { createIcon } from '@chakra-ui/react'

export const HometownTaxDonationIcon = createIcon({
  displayName: 'HometownTaxDonationIcon ',
  viewBox: '0 0 28 28',
  path: (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0007 22.1667C19.4775 22.1667 23.9173 18.049 23.9173 12.25H4.08398C4.08398 18.049 8.52385 22.1667 14.0007 22.1667Z"
          fill="#6E7DCA"
          stroke="#0D0D0D"
          strokeWidth="2"
        />
        <path
          d="M17.4993 12.2493C17.4993 9.02772 15.0183 6.41602 11.9577 6.41602C8.89711 6.41602 6.41602 9.02772 6.41602 12.2493"
          stroke="#0D0D0D"
          strokeWidth="2"
        />
        <path
          d="M22.7493 12.25C22.7493 10.317 21.1358 8.75 19.1455 8.75C18.3038 8.75 17.5294 9.03029 16.916 9.49999"
          stroke="#0D0D0D"
          strokeWidth="2"
        />
        <path
          d="M19.25 8.74935L21 2.91602"
          stroke="#0D0D0D"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path
          d="M22.166 10.4993L24.4993 6.41602"
          stroke="#0D0D0D"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path
          d="M10.5007 21.584L9.33398 25.084H18.6673L17.5007 21.584"
          stroke="#0D0D0D"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </svg>
    </>
  )
})
