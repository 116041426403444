import { Button, ButtonProps } from '@chakra-ui/react'
import { useTaxTaskPageContext } from 'src/pages/tax_tasks/contexts/TaxTaskPageContext'

export const NextStepButton: React.FC<ButtonProps> = (props) => {
  const { stepIndex, setStepIndex } = useTaxTaskPageContext()

  return (
    <Button
      background={'orange.primary'}
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.1)"
      h="48px"
      w="166px"
      fontWeight={700}
      fontSize="14px"
      onClick={() => setStepIndex(stepIndex + 1)}
      {...props}
    >
      次のステップに進む
    </Button>
  )
}
