import { createIcon } from '@chakra-ui/react'

export const TrashIcon = createIcon({
  displayName: 'TrashIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 4H3.33333H14"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.3335 4.00065V2.66732C5.3335 2.3137 5.47397 1.97456 5.72402 1.72451C5.97407 1.47446 6.31321 1.33398 6.66683 1.33398H9.3335C9.68712 1.33398 10.0263 1.47446 10.2763 1.72451C10.5264 1.97456 10.6668 2.3137 10.6668 2.66732V4.00065M12.6668 4.00065V13.334C12.6668 13.6876 12.5264 14.0267 12.2763 14.2768C12.0263 14.5268 11.6871 14.6673 11.3335 14.6673H4.66683C4.31321 14.6673 3.97407 14.5268 3.72402 14.2768C3.47397 14.0267 3.3335 13.6876 3.3335 13.334V4.00065H12.6668Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
})
