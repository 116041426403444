import { TabPanel, TabPanelProps } from '@chakra-ui/react'

export const MonthlyTasksTabPanelSubTabPanel: React.FC<TabPanelProps> = ({
  children,
  ...props
}) => {
  return (
    <TabPanel
      p="16px"
      bgColor="gray.50"
      borderLeft="1px solid"
      borderColor="gray.250"
      h="100%"
      overflow="scroll"
      {...props}
    >
      {children}
    </TabPanel>
  )
}
