import { Box, chakra, Flex, Text, useBoolean } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { CompleteCheckCircleIcon } from 'src/components/common/Icons/CompleteCheckCircleIcon'
import { useTransactionsProgress } from 'src/hooks/at_transactions/useTransactionsProgress'
import { useAccounts } from 'src/hooks/useAccounts'
import { useActiveSubscription } from 'src/hooks/useActiveSubscription'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useTaxTaskPageContext } from 'src/pages/tax_tasks/contexts/TaxTaskPageContext'
import { progressbarAnimation } from 'src/styles/animations'
import { AccountConnectFailed } from '../../../AccountConnectFailed'
import { TaxChart } from '../../../TaxChart'
import { TaskButton } from '../../TaskButton'

const PREV_COMPLETE_KEY = 'prev_complete_key'
const mapIndexToMonth = (index: number) => {
  switch (index) {
    case 0:
      return 'jan'
    case 1:
      return 'feb'
    case 2:
      return 'mar'
    case 3:
      return 'apr'
    case 4:
      return 'may'
    case 5:
      return 'jun'
    case 6:
      return 'jul'
    case 7:
      return 'aug'
    case 8:
      return 'sep'
    case 9:
      return 'oct'
    case 10:
      return 'nov'
    case 11:
    default:
      return 'dec'
  }
}

export const Transactions: React.FC = () => {
  const { transactionsProgress } = useTransactionsProgress()
  const mountedRef = useRef(false)
  const { processing_year } = useProcessingYear()
  const { activeSubscription } = useActiveSubscription()
  const { failedAccounts } = useTaxTaskPageContext()
  const { isNotConnected } = useAccounts()
  const [shoudShowAlert, { on, off }] = useBoolean()
  const isNotConnectedOnFreeTrial =
    activeSubscription?.is_freetrial && isNotConnected

  // 前回開いたときに完了していたはずの登録タスクが,
  // 新規取引が追加されるなどして完了じゃなくなったとき
  // アラートを出す
  useEffect(() => {
    if (mountedRef.current || transactionsProgress === undefined) {
      return
    }
    mountedRef.current = true

    // 前回このコンポーネントを表示したときに登録タスクは完了していたかどうか
    const prevFlagString = localStorage.getItem(PREV_COMPLETE_KEY)
    const currentComplete = transactionsProgress.is_finished

    // 前回完了だったものが今回完了になっていなかった場合にアラートを出す
    if (prevFlagString === 'true' && currentComplete === false) {
      on()
    } else {
      off()
    }
    // 今回の完了状態をlocal storageに保存
    localStorage.setItem(PREV_COMPLETE_KEY, currentComplete ? 'true' : 'false')
  }, [transactionsProgress])

  return (
    <Flex justify="space-between">
      <Box w={['100%', '360px']}>
        {shoudShowAlert && (
          <Text
            fontSize="13px"
            fontWeight={500}
            lineHeight="21px"
            color="primary.purple"
            bgColor="purple.50"
            py="4px"
            px="12px"
            mb="12px"
          >
            新しい履歴が追加されました。登録を進めましょう
          </Text>
        )}
        {failedAccounts && failedAccounts.length > 0 && (
          <AccountConnectFailed accounts={failedAccounts} />
        )}
        <Flex gap="8px" flexWrap="wrap">
          {[...Array(12)].map((v, i) => {
            const monthProgress =
              transactionsProgress?.months[mapIndexToMonth(i)]
            const progressInPercnetage = monthProgress?.journalized_rate
            const isComplete = progressInPercnetage === 100
            // ある月でAT側に取引が1件も登録されていないかどうか
            const hasNoTransactions =
              monthProgress?.journalized_count === 0 &&
              monthProgress?.unjournalized_count === 0
            return (
              <NextLink
                key={i + 1}
                href={
                  hasNoTransactions
                    ? ''
                    : `/transactions/${processing_year?.processing_year}/${
                        i + 1
                      }`
                }
                passHref
              >
                <TaskButton
                  w="calc((100% - 16px) / 3)"
                  flexDir="column"
                  gap="12px"
                  isDemo={false}
                  isNotConnectedOnFreeTrial={!!isNotConnectedOnFreeTrial}
                  linkType="internal"
                  {...(hasNoTransactions && {
                    bgColor: 'gray.200'
                  })}
                  isDisabled={hasNoTransactions}
                  sx={{
                    '&[disabled]': {
                      opacity: 1
                    }
                  }}
                >
                  <Flex
                    alignItems={'baseline'}
                    gap="4px"
                    color={hasNoTransactions ? 'gray.600' : 'primary.black'}
                  >
                    <chakra.span
                      fontSize={'24px'}
                      lineHeight="20px"
                      fontWeight={'800'}
                      fontFamily={'Open Sans'}
                    >
                      {i + 1}
                    </chakra.span>
                    <chakra.span fontSize="12px" fontWeight={500}>
                      月
                    </chakra.span>
                  </Flex>
                  {hasNoTransactions ? (
                    <Text
                      fontSize="10px"
                      fontWeight={500}
                      lineHeight="10px"
                      color="gray.600"
                    >
                      出入金記録なし
                    </Text>
                  ) : (
                    <Flex
                      h="6px"
                      align="center"
                      w="77px"
                      justify="space-between"
                    >
                      <Box
                        borderRadius="1px"
                        position="relative"
                        w={isComplete ? '61px' : '52px'}
                        h="100%"
                        bgColor="gray.100"
                      >
                        <Box
                          borderRadius="2px"
                          bgColor="primary.purple"
                          position="absolute"
                          left={0}
                          h="100%"
                          animation={progressbarAnimation(progressInPercnetage)}
                        />
                      </Box>
                      {isComplete ? (
                        <CompleteCheckCircleIcon w="10px" h="10px" />
                      ) : (
                        <Text
                          fontSize="10px"
                          fontWeight={600}
                          lineHeight="10px"
                          color="gray.800"
                        >
                          {`${progressInPercnetage || 0}%`}
                        </Text>
                      )}
                    </Flex>
                  )}
                </TaskButton>
              </NextLink>
            )
          })}
        </Flex>
      </Box>
      <Box>{!isMobile && <TaxChart isDemo={false} />}</Box>
    </Flex>
  )
}
