import { createIcon } from '@chakra-ui/react'

export const InvoiceIcon = createIcon({
  displayName: 'InvoiceIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M19 2H5C4.44771 2 4 2.44771 4 3V21C4 21.5523 4.44771 22 5 22H19C19.5523 22 20 21.5523 20 21V3C20 2.44771 19.5523 2 19 2Z"
        fill="white"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8C14 8.69035 13.7202 9.31535 13.2677 9.76775C12.8153 10.2202 12.1904 10.5 11.5 10.5C10.1193 10.5 9 9.3807 9 8C9 6.6193 10.1193 5.5 11.5 5.5C12.8807 5.5 14 6.6193 14 8Z"
        fill="white"
      />
      <path
        d="M15 11.5L13.2677 9.76775L15 11.5ZM13.2677 9.76775C13.7202 9.31535 14 8.69035 14 8C14 6.6193 12.8807 5.5 11.5 5.5C10.1193 5.5 9 6.6193 9 8C9 9.3807 10.1193 10.5 11.5 10.5C12.1903 10.5 12.8153 10.2202 13.2677 9.76775Z"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 15H15.5"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 18H12"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
