import { Box, Flex, HStack, Tab, TabProps, Text } from '@chakra-ui/react'
import { CautionIcon } from 'src/components/common/Icons/CautionIcon'
import { useTaxTaskPageContext } from 'src/pages/tax_tasks/contexts/TaxTaskPageContext'
import { IsSyncingAccountLabel } from '../IsSyncingAccountLabel'

type MonthlyTasksTabProps = {
  count?: number
  selected: boolean
}
export const MonthlyTasksTabPanelSubTab: React.FC<
  TabProps & MonthlyTasksTabProps
> = ({ children, count, selected, ...props }) => {
  const { isSyncing, failedAccountConnection } = useTaxTaskPageContext()

  return (
    <Tab
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      fontWeight={700}
      fontSize="14px"
      px={['12px', '16px']}
      py={children === '収入・経費' && failedAccountConnection ? 10 : 6}
      w="100%"
      h="64px"
      _selected={{
        boxShadow: '3px 0 0 0 rgba(110, 125, 202, 1) inset',
        bgColor: 'purple.50'
      }}
      _focus={{
        boxShadow: '3px 0 0 0 rgba(110, 125, 202, 1) inset'
      }}
      pos="relative"
      {...props}
    >
      <Box>
        <Flex align="center" gap="6px">
          <Text color={selected ? 'primary.black' : 'gray.400'}>
            {children}
          </Text>
          {count ? (
            <Text
              w={['18px', '20px']}
              h={['18px', '20px']}
              fontSize={['11px', '12px']}
              lineHeight={['11px', '12px']}
              py={['3.5px', '4px']}
              borderRadius="full"
              color="white"
              bgColor={selected ? 'primary.purple' : 'gray.400'}
            >
              {count}
            </Text>
          ) : null}
        </Flex>
        {children === '収入・経費' && failedAccountConnection && (
          <HStack
            backgroundColor="cost.100"
            border="1px solid"
            borderColor="#E0B3B2"
            borderRadius="2px"
            alignItems="center"
            mt="6px"
            px="8px"
            py="4px"
          >
            <CautionIcon height="18px" width="18px" />
            <Text
              fontSize="13px"
              fontWeight="700"
              lineHeight="1.6"
              textAlign="left"
            >
              連携切れあり
            </Text>
          </HStack>
        )}
      </Box>
      {children === '収入・経費' && isSyncing ? (
        <IsSyncingAccountLabel />
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33334 4L10.3333 8L6.33334 12"
            stroke="rgba(196, 196, 196, 1)"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
      )}
    </Tab>
  )
}
