import { createIcon } from '@chakra-ui/react'

export const MoneyIcon = createIcon({
  displayName: 'MoneyIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83332 6.2526C8.13451 6.2526 9.99999 5.31986 9.99999 4.16927C9.99999 3.01868 8.13451 2.08594 5.83332 2.08594C3.53214 2.08594 1.66666 3.01868 1.66666 4.16927C1.66666 5.31986 3.53214 6.2526 5.83332 6.2526Z"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1.66666 4.16406C1.66666 4.16406 1.66666 5.93015 1.66666 7.08073C1.66666 8.23131 3.53214 9.16406 5.83332 9.16406C8.13449 9.16406 9.99999 8.23131 9.99999 7.08073C9.99999 6.39923 9.99999 4.16406 9.99999 4.16406"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1.66666 7.08594C1.66666 7.08594 1.66666 8.85202 1.66666 10.0026C1.66666 11.1532 3.53214 12.0859 5.83332 12.0859C8.13449 12.0859 9.99999 11.1532 9.99999 10.0026C9.99999 9.3211 9.99999 7.08594 9.99999 7.08594"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1.66666 10C1.66666 10 1.66666 11.7661 1.66666 12.9167C1.66666 14.0672 3.53214 15 5.83332 15C8.13449 15 9.99999 14.0672 9.99999 12.9167C9.99999 12.2352 9.99999 10 9.99999 10"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1.66666 12.9141C1.66666 12.9141 1.66666 14.6801 1.66666 15.8307C1.66666 16.9813 3.53214 17.9141 5.83332 17.9141C8.13449 17.9141 9.99999 16.9813 9.99999 15.8307C9.99999 15.1492 9.99999 12.9141 9.99999 12.9141"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 12.0807C16.4679 12.0807 18.3333 11.148 18.3333 9.9974C18.3333 8.8468 16.4679 7.91406 14.1667 7.91406C11.8655 7.91406 10 8.8468 10 9.9974C10 11.148 11.8655 12.0807 14.1667 12.0807Z"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M10 10C10 10 10 11.7661 10 12.9167C10 14.0672 11.8655 15 14.1667 15C16.4678 15 18.3333 14.0672 18.3333 12.9167C18.3333 12.2352 18.3333 10 18.3333 10"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.9141C10 12.9141 10 14.6801 10 15.8307C10 16.9813 11.8655 17.9141 14.1667 17.9141C16.4678 17.9141 18.3333 16.9813 18.3333 15.8307C18.3333 15.1492 18.3333 12.9141 18.3333 12.9141"
        stroke="#A0A0A0"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
})
