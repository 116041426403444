import {
  Center,
  TabList,
  TabPanelProps,
  TabPanels,
  Tabs,
  Text,
  useBoolean,
  VStack
} from '@chakra-ui/react'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { response_task } from 'src/api'
import { CardIcon } from 'src/components/common/Icons/Card'
import { ItemOfAccount, useAccounts } from 'src/hooks/useAccounts'
import { useApiClient } from 'src/hooks/useApiClient'
import { useTasks } from 'src/hooks/useTasks'
import { useDemoContext } from 'src/pages/demo/contexts/DemoContext'
import { useTaxTaskPageContext } from 'src/pages/tax_tasks/contexts/TaxTaskPageContext'
import { IconAccountTrackerCardButton } from './IconAccountTrackerCardButton'
import { IsSyncingAccountButton } from './IsSyncingAccountButton'
import { MonthlyTasksTabPanelSubTab } from './MonthlyTasksTabPanelSubTab'
import { MonthlyTasksTabPanelSubTabPanel } from './MonthlyTasksTabPanelSubTabPanel'
import { DocumentPanel } from './Panels/DocumentPanel'
import { InvoicePanel } from './Panels/InvoicePanel'
import { SaveTaxPanel } from './Panels/SaveTaxPanel'
import { TransactionsPanel } from './Panels/TransactionsPanel'

const getRequiredTaskCount = (
  tasks?: response_task[],
  failedAccounts?: ItemOfAccount[]
) => {
  const requiredTaskCount =
    tasks?.filter((task) => task.task_type === response_task.task_type.REQUIRED)
      .length || 0

  const failedAccountCount = failedAccounts?.length || 0
  return requiredTaskCount + failedAccountCount
}

const Notasks: React.FC<TabPanelProps> = ({ ...props }) => (
  <MonthlyTasksTabPanelSubTabPanel {...props}>
    <Center
      h="100%"
      fontSize="17px"
      lineHeight="27.2px"
      textAlign="center"
      color="gray.600"
      fontWeight={700}
    >
      現在タスクはありません
    </Center>
  </MonthlyTasksTabPanelSubTabPanel>
)

export const MonthlyTasksTabPanel: React.VFC = () => {
  const { isDemo, nextDemoStep } = useDemoContext()
  const { apiClient } = useApiClient()
  const { tasks } = useTasks()
  const { isNotConnected } = useAccounts()

  const { isSyncing, failedAccounts } = useTaxTaskPageContext()
  const [isLoading, { on, off }] = useBoolean(false)
  const [tabIndex, setTabIndex] = useState(0)

  const redirectToAccountTracker = () => {
    on()
    const windowReference = window.open('', '_blank')
    apiClient.getAccountTrackerLink(location.href).then((res) => {
      if (windowReference) windowReference.location = res.redirect_to
      off()
    })
  }

  return (
    <Tabs
      variant="unstyled"
      index={tabIndex}
      onChange={setTabIndex}
      border="1px solid"
      borderColor="gray.250"
      borderRadius="2px"
      display="flex"
      id="pc-monthly-tasks-tab-content"
      w="720px"
      h="460px"
    >
      <TabList display="flex" flexDir="column">
        <VStack w="240px" align="flex-start" spacing={0}>
          <MonthlyTasksTabPanelSubTab
            selected={tabIndex === 0}
            count={getRequiredTaskCount(tasks?.journal, failedAccounts)}
          >
            収入・経費
          </MonthlyTasksTabPanelSubTab>
          <MonthlyTasksTabPanelSubTab
            selected={tabIndex === 1}
            count={getRequiredTaskCount(tasks?.invoice)}
          >
            請求書
          </MonthlyTasksTabPanelSubTab>
          <MonthlyTasksTabPanelSubTab
            selected={tabIndex === 2}
            count={getRequiredTaskCount(tasks?.document)}
          >
            書類保管
          </MonthlyTasksTabPanelSubTab>
          <MonthlyTasksTabPanelSubTab
            selected={tabIndex === 3}
            count={getRequiredTaskCount(tasks?.tax)}
            data-joyride-id="pc-monthly-tasks-tax-tab-button"
            onClick={(e) => {
              e.stopPropagation()
              isDemo && nextDemoStep()
            }}
          >
            節税
          </MonthlyTasksTabPanelSubTab>
        </VStack>
      </TabList>
      <TabPanels>
        {isNotConnected ? (
          <MonthlyTasksTabPanelSubTabPanel>
            <IconAccountTrackerCardButton
              isDemo={isDemo}
              icon={<CardIcon />}
              task={{
                name: isMobile
                  ? '口座・クレカ連携'
                  : '銀行口座・クレカを連携して明細を取得'
              }}
            />
          </MonthlyTasksTabPanelSubTabPanel>
        ) : isSyncing ? (
          <MonthlyTasksTabPanelSubTabPanel>
            <IsSyncingAccountButton
              isDemo={isDemo}
              isLoading={isLoading}
              onClick={redirectToAccountTracker}
            />
          </MonthlyTasksTabPanelSubTabPanel>
        ) : tasks && tasks.journal && tasks.journal.length > 0 ? (
          <TransactionsPanel journal={tasks.journal} isDemo={isDemo} />
        ) : (
          <Notasks />
        )}
        {tasks && tasks.invoice && tasks.invoice.length > 0 ? (
          <InvoicePanel invoice={tasks.invoice} isDemo={isDemo} />
        ) : (
          <Notasks />
        )}
        {tasks && tasks.document && tasks.document.length > 0 ? (
          <DocumentPanel document={tasks.document} isDemo={isDemo} />
        ) : (
          <Notasks />
        )}
        {tasks && tasks.tax && tasks.tax.length > 0 ? (
          <SaveTaxPanel tax={tasks.tax} isDemo={isDemo} />
        ) : (
          <Notasks />
        )}
        <MonthlyTasksTabPanelSubTabPanel>
          <Center
            h="100%"
            fontSize="17px"
            lineHeight="27.2px"
            textAlign="center"
            color="gray.600"
            fontWeight={700}
          >
            <Text>
              左リストの中から
              <br />
              取り組むタスクを選択
              <Text as="span" display={['none', 'inline']}>
                しましょう
              </Text>
              ！
            </Text>
          </Center>
        </MonthlyTasksTabPanelSubTabPanel>
      </TabPanels>
    </Tabs>
  )
}
