import {
  Box,
  Button,
  ButtonProps,
  LinkProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { EncourageConnectionModal } from './EncourageConnectionModal'

export const EncourageConnectionTooltip: React.FC<ButtonProps & LinkProps> = ({
  children,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure()

  return (
    <>
      <Popover
        placement="bottom"
        isOpen={isOpen}
        onClose={onClose}
        closeDelay={200}
      >
        <PopoverTrigger>
          <Box
            onClick={onOpen}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            w={props.w}
          >
            {children}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          w={['232px', '256px']}
          h="80px"
          alignItems="center"
          bg="purple.900"
          borderRadius="4px"
          color="white.100"
          fontSize="13px"
          lineHeight="19.5px"
          fontWeight={700}
          _focus={{ outline: 'none' }}
        >
          <PopoverCloseButton d={['none', 'block']} />
          <PopoverBody h="100%" w="100%" py="15.5px" pl="16px" pb="11.5px">
            <Text fontSize="14px" lineHeight={1} fontWeight={700}>
              口座・クレカの連携が必要です
            </Text>
            <Button
              mt="10px"
              onClick={onModalOpen}
              minH="0px"
              px="12px"
              py="8px"
              fontSize="13px"
              lineHeight={1}
              fontWeight={700}
              h="fit-content"
            >
              連携する
            </Button>
          </PopoverBody>
          <PopoverArrow bg="purple.900" />
        </PopoverContent>
      </Popover>
      {/* 連携を促すモーダル */}
      <EncourageConnectionModal onClose={onModalClose} isOpen={isModalOpen} />
    </>
  )
}

export const WithEncourageConnectionTooltip = (
  isNotConnected: boolean,
  component: JSX.Element,
  props?: ButtonProps & LinkProps
) => {
  return isNotConnected ? (
    <EncourageConnectionTooltip {...props}>
      {component}
    </EncourageConnectionTooltip>
  ) : (
    component
  )
}
