import { Flex, TabPanelProps } from '@chakra-ui/react'
import NextLink from 'next/link'
import { isMobile } from 'react-device-detect'
import { response_task } from 'src/api'
import { CardIcon } from 'src/components/common/Icons/Card'
import { useAccounts } from 'src/hooks/useAccounts'
import { useActiveSubscription } from 'src/hooks/useActiveSubscription'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useTaxTaskPageContext } from 'src/pages/tax_tasks/contexts/TaxTaskPageContext'
import { PAGE_PATH } from 'src/routes'
import { IconAccountTrackerCardButton } from '../IconAccountTrackerCardButton'
import { MonthlyTaskCardButton } from '../MonthlyTaskCardButton'
import { MonthlyTasksTabPanelSubTabPanel } from '../MonthlyTasksTabPanelSubTabPanel'

type Props = {
  journal: response_task[]
  isDemo: boolean
}
export const TransactionsPanel: React.FC<TabPanelProps & Props> = ({
  journal,
  isDemo,
  ...props
}) => {
  const { processing_year } = useProcessingYear()
  const { failedAccounts } = useTaxTaskPageContext()
  const { isNotConnected } = useAccounts()
  const { activeSubscription } = useActiveSubscription()

  const isNotConnectedOnFreeTrial =
    !isDemo && activeSubscription?.is_freetrial && isNotConnected

  return (
    <MonthlyTasksTabPanelSubTabPanel {...props}>
      <Flex flexDir="column" gap="8px">
        {failedAccounts &&
          failedAccounts.length > 0 &&
          failedAccounts.map((account, i) => (
            <IconAccountTrackerCardButton
              isDemo={isDemo}
              key={i}
              icon={<CardIcon />}
              task={{
                name: isMobile
                  ? '「' + account.name + '」連携'
                  : '「' + account.name + '」を再連携して明細を取得'
              }}
              account={account}
            />
          ))}
        {isNotConnectedOnFreeTrial
          ? [...Array(12)].map((_, i) => (
              <MonthlyTaskCardButton
                key={i}
                month={i + 1}
                // ダミーで入れる
                task={{
                  name: '',
                  category: response_task.category.JOURNAL,
                  task_type: response_task.task_type.REQUIRED,
                  code: '',
                  order_no: i,
                  url: '',
                  url_type: response_task.url_type.INTERNAL,
                  is_archived: false
                }}
                isNotConnectedOnFreeTrial={true}
                isDemo={isDemo}
              />
            ))
          : journal
              .map((task, i) => {
                const month = task.code
                  ? Number(task.code.match(/journal_(\d+)/)?.[1])
                  : 1
                return (
                  <NextLink
                    key={i}
                    passHref
                    href={PAGE_PATH.transactions(
                      processing_year?.processing_year ||
                        new Date().getFullYear(),
                      month
                    )}
                  >
                    <MonthlyTaskCardButton
                      month={month}
                      task={task}
                      isNotConnectedOnFreeTrial={false}
                      isDemo={isDemo}
                    />
                  </NextLink>
                )
              })
              .reverse()}
      </Flex>
    </MonthlyTasksTabPanelSubTabPanel>
  )
}
