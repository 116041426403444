import { createIcon } from '@chakra-ui/react'

export const MynumberIcon = createIcon({
  displayName: 'MynumberIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 4H3C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V5C22 4.44772 21.5523 4 21 4Z"
        stroke="#0D0D0D"
        strokeWidth="2"
      />
      <path
        d="M8.5 12.5C9.60455 12.5 10.5 11.6046 10.5 10.5C10.5 9.39545 9.60455 8.5 8.5 8.5C7.39545 8.5 6.5 9.39545 6.5 10.5C6.5 11.6046 7.39545 12.5 8.5 12.5Z"
        stroke="#0D0D0D"
        strokeWidth="2"
      />
      <path
        d="M11.5 15.5C11.5 13.8432 10.1568 12.5 8.5 12.5C6.84315 12.5 5.5 13.8432 5.5 15.5"
        stroke="#0D0D0D"
        strokeWidth="2"
      />
      <path d="M14 10H18" stroke="#0D0D0D" strokeWidth="2" />
      <path d="M15 14H18" stroke="#0D0D0D" strokeWidth="2" />
    </svg>
  )
})
