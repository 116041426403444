import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  chakra,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import { response_taxReturnQuestions } from 'src/api/models/response_taxReturnQuestions'
import { MoneyIcon } from 'src/components/common/Icons/MoneyIcon'
import { OutlineCardIcon } from 'src/components/common/Icons/OutlineCardIcon'
import { RefreshIcon } from 'src/components/common/Icons/RefreshIcon'
import { SquareCheckIcon } from 'src/components/common/Icons/SquareCheckIcon'
import { useTransactionsProgress } from 'src/hooks/at_transactions/useTransactionsProgress'
import { useTaxReturnQuestion } from 'src/hooks/useTaxReturnQuestion'
import { useUserIsReady } from 'src/hooks/useUserIsReady'
import { useTaxTaskPageContext } from 'src/pages/tax_tasks/contexts/TaxTaskPageContext'
import { refreshIconAnimation } from 'src/styles/animations'
import { Accounts } from './Contents/Accounts'
import { FinalCheck } from './Contents/FinalCheck'
import { Transactions } from './Contents/Transactions'
import { EntireFlowTabContent } from './EntireFlowTabContent'
import { usePollingATSyncingStatus } from './hooks/usePollingATSyncingStatus'
import {
  EntireFlowHeader,
  MonthlyTasksTabPanelSubTab
} from './MonthlyTasksTabPanelSubTab'

const { checked_business_income } = response_taxReturnQuestions

const AccountTitleOnSyncing = (
  <Flex gap="8px" align="center">
    <RefreshIcon
      h={['18px', '24px']}
      w={['18px', '24px']}
      animation={refreshIconAnimation}
    />
    <chakra.span
      fontSize={['13px', '18px']}
      fontWeight={700}
      lineHeight={1.5}
      color="primary.purple"
    >
      クレカ・口座を連携中です。
      <chakra.span
        fontSize={['12px', '14px']}
        fontWeight={700}
        lineHeight={1.5}
        color="gray.700"
      >
        （目安3-5分）
      </chakra.span>
    </chakra.span>
  </Flex>
)

// PC, SPで共有するコンテンツの配列
const entireFlowContent: Array<{
  title: string
  icon: JSX.Element
  subtitle: { pc: string; sp: JSX.Element | string }
  content: JSX.Element
}> = [
  // {
  //   title: '進め方診断',
  //   subtitle: {
  //     pc: `あなたに合った${SERVICE_NAME}の進め方を診断します！`,
  //     sp: (
  //       <>
  //         あなたに合った{SERVICE_NAME}の進め方を、
  //         <br />
  //         診断します！
  //       </>
  //     )
  //   },
  //   content: <TypeForm />,
  //   icon: <CheckListIcon w="20px" h="20px" />
  // },
  {
    title: 'クレカ・口座を連携',
    subtitle: {
      pc: 'クレカ・口座を連携しましょう',
      sp: 'クレカ・口座を連携しましょう！'
    },
    content: <Accounts />,
    icon: <OutlineCardIcon w="20px" h="20px" />
  },
  {
    title: '収入・経費の登録',
    subtitle: {
      pc: '口座・クレカの履歴を、月ごとに整理しましょう',
      sp: (
        <>
          口座・クレカの履歴を、
          <br /> 月ごとに整理しましょう
        </>
      )
    },
    content: <Transactions />,
    icon: <MoneyIcon w="20px" h="20px" />
  },
  {
    title: '最終チェック',
    subtitle: { pc: '登録内容の最終チェック', sp: '' },
    content: <FinalCheck />,
    icon: <SquareCheckIcon w="20px" h="20px" />
  }
]

const ACCOUNT_INDEX = 0
const TRANSACTION_INDEX = 1
const FINAL_CHECK_INDEX = 2

// SPのときに表示するアコーディオン形式のもの
export const EntireFlowAccordion: React.FC = () => {
  const router = useRouter()
  const { isReady } = useUserIsReady()
  const { stepIndex, setStepIndex } = useTaxTaskPageContext()
  const { syncingStatus } = usePollingATSyncingStatus({
    isPolling: stepIndex === 1 || stepIndex === 2
  })
  const { transactionsProgress } = useTransactionsProgress()
  const { questions } = useTaxReturnQuestion()
  const completeList = [
    // !!isReady?.is_ready,
    syncingStatus === 'connected' || syncingStatus === 'syncing',
    !!transactionsProgress?.is_finished,
    questions?.business_income?.checked_business_income ===
      checked_business_income.YES
  ]

  const isMountedRef = useRef(false)
  useEffect(() => {
    if (router.query.show_transactions === 'true') {
      setStepIndex(TRANSACTION_INDEX)
    } else if (router.query.show_final_check === 'true') {
      setStepIndex(FINAL_CHECK_INDEX)
    } else if (
      isMountedRef.current === false &&
      isReady !== undefined &&
      syncingStatus !== undefined
    ) {
      isMountedRef.current = true
      setStepIndex(completeList.findIndex((complete) => !complete))
    }
  }, [router, isReady, syncingStatus])

  return (
    <Accordion
      index={stepIndex}
      onChange={(index) => setStepIndex(index as number)}
      allowToggle
      w="100%"
      sx={{
        '.chakra-accordion__item:first-child .task-tab-vertical-bar': {
          height: 'calc(100% - 10px)',
          top: 'initial',
          bottom: 0
        },
        '.chakra-accordion__item:last-child .task-tab-vertical-bar': {
          height: 'calc(100% - 10px)'
        }
      }}
      border="1px solid"
      borderColor="gray.250"
    >
      {completeList.map((completed, i) => (
        <AccordionItem p={0} key={i} border="none">
          <AccordionButton p={0} _focus={{ outline: 'none' }}>
            <EntireFlowHeader
              selected={stepIndex === i}
              completed={completed}
              icon={entireFlowContent[i].icon}
            >
              {entireFlowContent[i].title}
            </EntireFlowHeader>
          </AccordionButton>
          <AccordionPanel pt="16px" px="20px" pb="44px">
            <Text fontSize="15px" fontWeight={700} lineHeight="23px" mb="12px">
              {stepIndex === 1 && syncingStatus === 'syncing'
                ? AccountTitleOnSyncing
                : entireFlowContent[i].subtitle.sp}
            </Text>

            {entireFlowContent[i].content}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

// PCで表示するタブ形式のもの
export const EntireFlowTabPanel: React.VFC = () => {
  const router = useRouter()
  const { isReady } = useUserIsReady()
  const { stepIndex, setStepIndex } = useTaxTaskPageContext()
  const { syncingStatus } = usePollingATSyncingStatus({
    isPolling: stepIndex === 1 || stepIndex === 2
  })
  const { transactionsProgress } = useTransactionsProgress()
  const { questions } = useTaxReturnQuestion()
  const completeList = [
    // !!isReady?.is_ready,
    syncingStatus === 'connected' || syncingStatus === 'syncing',
    !!transactionsProgress?.is_finished,
    questions?.business_income?.checked_business_income ===
      checked_business_income.YES
  ]
  const isMountedRef = useRef(false)
  useEffect(() => {
    if (router.query.show_transactions === 'true') {
      setStepIndex(TRANSACTION_INDEX)
    } else if (router.query.show_final_check === 'true') {
      setStepIndex(FINAL_CHECK_INDEX)
    } else if (
      isMountedRef.current === false &&
      isReady !== undefined &&
      syncingStatus !== undefined
    ) {
      isMountedRef.current = true
      setStepIndex(completeList.findIndex((complete) => !complete))
    }
  }, [router, isReady, syncingStatus])

  return (
    <Tabs
      variant="unstyled"
      index={stepIndex}
      onChange={setStepIndex}
      border="1px solid"
      borderColor="gray.250"
      borderRadius="2px"
      display="flex"
      id="entire-flow-tab"
      w="960px"
      h={isReady?.is_ready ? '546px' : '470px'}
    >
      <TabList display="flex" flexDir="column">
        <VStack
          w="239px"
          h="100%"
          align="flex-start"
          spacing={0}
          borderRight="1px solid"
          borderColor="gray.250"
          sx={{
            'button:first-child .task-tab-vertical-bar': {
              height: 'calc(100% - 10px)',
              top: 'initial',
              bottom: 0
            },
            'button:last-child .task-tab-vertical-bar': {
              height: 'calc(100% - 10px)'
            }
          }}
        >
          {completeList.map((completed, i) => (
            <MonthlyTasksTabPanelSubTab
              key={i}
              selected={stepIndex === i}
              completed={completed}
              icon={entireFlowContent[i].icon}
            >
              {entireFlowContent[i].title}
            </MonthlyTasksTabPanelSubTab>
          ))}
        </VStack>
      </TabList>
      <TabPanels bgColor="gray.50">
        {entireFlowContent.map(({ subtitle, content }, i) => (
          <TabPanel p="0" key={i}>
            <EntireFlowTabContent
              subtitle={
                stepIndex === 1 && syncingStatus === 'syncing'
                  ? AccountTitleOnSyncing
                  : subtitle.pc
              }
              content={content}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
