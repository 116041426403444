import {
  Box,
  ButtonProps,
  Center,
  CircularProgress,
  Flex,
  LinkProps,
  Text,
  useBoolean
} from '@chakra-ui/react'
import React from 'react'
import { response_task } from 'src/api'
import { ItemOfAccount } from 'src/hooks/useAccounts'
import { useApiClient } from 'src/hooks/useApiClient'
import { TaskButton } from '../TaskButton'

type Props = {
  icon: JSX.Element
  task: Pick<response_task, 'name'>
  account?: ItemOfAccount
}

export const IconAccountTrackerCardButton = React.forwardRef<
  HTMLButtonElement,
  Props & ButtonProps & LinkProps & { isDemo: boolean }
>(({ icon, task, account, ...props }, ref) => {
  const { apiClient } = useApiClient()
  const [isLoading, { on, off }] = useBoolean(false)

  const redirectToAccountTracker = () => {
    on()
    const windowReference = window.open('', '_blank')
    const accountId = account ? account.account_id : null
    apiClient.getAccountTrackerLink(location.href, accountId).then((res) => {
      if (windowReference) windowReference.location = res.redirect_to
      off()
    })
  }

  return (
    <TaskButton
      w="100%"
      minH={'56px'}
      h={['auto', '56px']}
      px={['8px', '16px']}
      py={['16px', '18px']}
      justifyContent="space-between"
      ref={ref}
      _hover={{ backgroundColor: 'gray.50' }}
      flexWrap="nowrap"
      whiteSpace="normal"
      onClick={redirectToAccountTracker}
      {...props}
    >
      <Flex
        gap="10px"
        align="center"
        flexWrap="nowrap"
        justify={['space-between', 'flex-start']}
        w={['100%', 'fit-content']}
      >
        <Center minW="1%" display={['none', 'block']}>
          {isLoading ? (
            <CircularProgress isIndeterminate thickness="16px" size="20px" />
          ) : (
            icon
          )}
        </Center>
        <Text
          noOfLines={1}
          fontSize={['14px', '15px']}
          fontWeight={500}
          wordBreak="break-word"
          minW="0"
          lineHeight={1}
        >
          {isLoading ? '別タブを開きます...' : task.name}
        </Text>
        <Box
          bgColor="primary.purple"
          color="white.100"
          borderRadius="2px"
          border="none"
          borderColor="gray.200"
          fontSize="11px"
          lineHeight="1"
          fontWeight="500"
          py="3.5px"
          px="5px"
          minW="34px"
        >
          必須
        </Box>
      </Flex>
      <Text color="alert.primary" fontSize="13px">
        今すぐ
      </Text>
    </TaskButton>
  )
})
